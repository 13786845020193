import React, { Component } from "react";
import UserDataService from "../services/user.service";
import { Table, Button } from 'react-bootstrap';

import AuthService from "../services/auth.service";
import { Textbox, Checkbox } from 'react-inputs-validation';
import constants from './../constants.js';
import logo from "../assets/cmmc-logo-allwhite.png";

const ROLES = [
  {
    value: "readonly",
    text: "Read only"
  },
  {
    value: "questionnaireonly",
    text: "Questionnaire input only"
  },
  {
    value: "fullcontrol",
    text: "Administrator (Full Control)"
  }
];

export default class Users extends Component {
  constructor(props) {
    super(props);

    this.getUsers = this.getUsers.bind(this);
    this.editUser = this.editUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.newUserSubmit = this.newUserSubmit.bind(this);
    this.updateUserCancel = this.updateUserCancel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateNewUserForm = this.validateNewUserForm.bind(this);
    this.validateEditUserForm = this.validateEditUserForm.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      users: "",
      newUser: false,
      editUser: false,
      userId: "",
      firstName: "",
      lastName: "",
      role: "",
      userStatus: "",
      hasFirstNameError: "",
      hasLastNameError: "",
      hasEmailError: "",
      validate: false,
      resetPassword: false,
      emailMessage: ""
    };

  }
  toggleValidating(validate) {
    this.setState({ validate });
  }

  validateEditUserForm(e) {
    e.preventDefault();
    this.toggleValidating(true);
    this.setState({
      hasFirstNameError: false,
      hasLastNameError: false
    });

    let validationErrors={};
    if(this.state.firstName.length === 0) {
      validationErrors.firstNameError = true;
      this.setState({hasFirstNameError: true})
    }
    if(this.state.lastName.length === 0) {
      validationErrors.lastNameError = true;
      this.setState({hasLastNameError: true})
    }

    return Object.keys(validationErrors).length;
  }

  validateNewUserForm(e) {
    const email_reg = constants.Email_Validation_Regex;
    e.preventDefault();
    this.toggleValidating(true);

    this.setState({
      hasFirstNameError: false,
      hasLastNameError: false,
      hasEmailError: false
    });

    let validationErrors={};
    if(this.state.firstName.length === 0) {
      validationErrors.firstNameError = true;
      this.setState({hasFirstNameError: true})
    }
    if(this.state.lastName.length === 0) {
      validationErrors.lastNameError = true;
      this.setState({hasLastNameError: true})
    }
    if(this.state.email.length === 0 || !email_reg.test(String(this.state.email).toLowerCase())) {
      validationErrors.emailError = true;
      this.setState({hasEmailError: true})
    }

    return Object.keys(validationErrors).length;
  }

  componentDidMount() {
    this.getUsers();
  }

  // get list of uses by org
  getUsers() {
    UserDataService.getUsersByOrg(this.state.currentUser.organizationId)
      .then(response => {
        this.setState({
          users: response.data.data,
        });
      })
  }

  editUser(user) {
    this.setState({
      newUser: false,
      editUser: true,
      userId: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      userStatus: user.userStatus
    })
  }

  addUser() {
    this.setState({
      newUser: true,
      editUser: false,
      userId: "",
      email: "",
      firstName: "",
      lastName: "",
      role: "readonly",
      userStatus: "",
      resetPassword: false
    });

  }

  newUserCancel() {
    this.setState({
      newUser: false
    });
  }

  newUserSubmit(e) {
    // check form validation
    if(this.validateNewUserForm(e)) {
      return false;
    }

    let user = {
      id: this.state.userId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      userStatus: this.state.userStatus,
      organizationId: this.state.currentUser.organizationId,
      role: this.state.role
    }

    UserDataService.newUser(user)
      .then(res => {
        this.getUsers();
        this.setState({
          newUser: false
        });
      })
      .catch(err => {
        this.setState({
          emailMessage: "Email address is already in use."
        })
      })
  }

  updateUser(e) {
    // check form validation
    if(this.validateEditUserForm(e)) {
      return false;
    }

    // fix for changing an unconfirmed user to active
    let user = {
      id: this.state.userId,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      userStatus: this.state.userStatus,
      role: this.state.role
    }
    UserDataService.updateUser(user)
      .then(res => {
        this.getUsers();
        this.setState({
          editUser: false,
          resetPassword: false
        });
        // check for updated name for current user
        let loggedInUser = this.state.currentUser;
        if(loggedInUser.id === user.id){
          loggedInUser.name = user.firstName + " " + user.lastName;
          sessionStorage.setItem("user", JSON.stringify(loggedInUser));
          window.location.reload();
        }
      })
  }

  updateUserCancel() {
    this.setState({
      editUser: false,
      resetPassword: false
    });
  }

  handleChange() {

  }

  // handle input change
  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });

  }

  // submit reset request
  resetPassword() {
    //call auth service
    let body ={
      email: this.state.email
    }
    AuthService.forgotPassword(body)
      .then(res => {
        this.setState({
          resetPassword: true
        });
      })
  }

  //format dateString
  formatDate(date){
    let localDate = new Date(date);
    let dateString = (localDate.getMonth()+1)+"/"+localDate.getDate()+"/"+localDate.getFullYear();
    return ( dateString );
  }

  // ...
  render() {
    const {
      validate
    } = this.state;
    const email_reg = constants.Email_Validation_Regex;

    const STATUS = {
      0: "Blocked",
      1: "Active",
      2: "Unconfirmed"
    };

    return (
      <div className="col-xl-10 col-lg-11 col-md-12 col-sm-12 col-xs-12 users">
         <form onSubmit={this.validateEditUserForm}>
        <Table responsive className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 manage-users">
          <thead>
            <tr>
              <th>User</th>
              <th>Last Active</th>
              <th>Role</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          {this.state.users ? (
            <tbody>
              {this.state.users.map((user) => {

                if (this.state.editUser && this.state.userId === user.id) {
                  return (
                    <tr key={user.id}>
                      <td colSpan="5" className="edit-user">
                        <div className="user-container user-info col-lg-3 col-md-5 col-sm-5 col-xs-5">
                          <div className="user-info-wrapper">
                            <span className="user-icon"></span>
                            <span className="user-name">{this.state.firstName} {this.state.lastName}</span>
                            <span className="email">
                              <a className="email" href="">{this.state.email}</a>
                            </span>
                          </div>
                        </div>
                        <div className="user-container user-name col-lg-4 col-md-5 col-sm-5 col-xs-5">
                          <div className="field-wrapper first-name">
                            <label htmlFor="user-status">First Name:</label>
                            <div className=" react-inputs-validation__textbox__wrapper___3tnXv false false false">
                              <div className="">
                                <input className="form-control" type="text" name="firstName" id="firstName" placeholder="Enter first name" value={this.state.firstName}
                                      onChange={(e) =>{ if(e.target.value === '') this.setState({hasFirstNameError:true}); else this.setState({hasFirstNameError:false}); this.setState({'firstName': e.target.value})}}
                                      onBlur={(e) =>{ if(e.target.value === '') this.setState({hasFirstNameError:true}); else this.setState({hasFirstNameError:false}); this.setState({'firstName': e.target.value})}}
                                />
                              </div>
                            </div>
                            {this.state.hasFirstNameError === true && (
                              <div className="error message">First Name is required</div>
                            )}
                          </div>
                          <div className="field-wrapper last-name">
                            <label htmlFor="user-status">Last Name:</label>
                            <div className=" react-inputs-validation__textbox__wrapper___3tnXv false false false">
                              <div className="">
                                <input className="form-control" type="text" name="lastName" id="lastName" placeholder="Enter last name" value={this.state.lastName}
                                      onChange={(e) =>{ if(e.target.value === '') this.setState({hasLastNameError:true}); else this.setState({hasLastNameError:false}); this.setState({'lastName': e.target.value})}}
                                      onBlur={(e) =>{ if(e.target.value === '') this.setState({hasFirsthasLastNameErrorNameError:true}); else this.setState({hasLastNameError:false}); this.setState({'lastName': e.target.value})}}
                                />
                              </div>
                            </div>
                            {this.state.hasLastNameError === true && (
                              <div className="error message">Last Name is required</div>
                            )}
                          </div>
                        </div>
                        <div className="user-container user-status col-lg-4">
                          {this.state.currentUser.id === this.state.userId ? (
                            <div className="field-wrapper status">
                              <label htmlFor="user-status">Status:</label> Active
                            </div>
                          ):(
                            <div className="field-wrapper status">
                              <label htmlFor="user-status">Status:</label>
                              <select id="userStatus" name="userStatus" className="form-control" value={this.state.userStatus} onChange={this.handleInputChange}>
                                {user.userStatus}
                                <option value="1">Active</option>
                                <option value="0">Blocked</option>
                              </select>
                            </div>
                          )}
                           {this.state.currentUser.id === this.state.userId ? (
                             <div className="field-wrapper role">
                              <label htmlFor="role-status">Role:</label> Full Control
                             </div>
                           ):(
                            <div className="field-wrapper role">
                              <label htmlFor="role-status">Role:</label>
                              <select id="role" name="role" className="form-control" value={this.state.role} onChange={this.handleInputChange}>
                                {ROLES.map(role => (
                                   <option value={role.value}>{role.text}</option>
                                ))}
                              </select>
                            </div>
                           )}
                          {!this.state.resetPassword ? (
                            <div className="field-wrapper reset">
                              <span className="reset-password">
                                <button className="btn btn-med-blue" onClick={() => this.resetPassword()}>
                                  <span className="icon"></span>
                                  Reset Password
                                </button>
                              </span>
                            </div>
                          ):(
                            <div>
                              Password reset has been requested
                            </div>
                          )}
                        </div>

                        <div className="button-container col-lg-12 col-md-10 col-sm-6">
                          <button className="btn btn-success" onClick={this.updateUser}>Save</button>&nbsp;
                          <Button variant="secondary" onClick={() => this.updateUserCancel()}>Cancel</Button>{' '}
                        </div>
                      </td>
                    </tr>
                  )
                }
                else {
                  return (
                    <tr className="user" key={user.id}>
                      <td>{user.firstName} {user.lastName}<br /><a className="email">{user.email}</a></td>
                      <td>{this.formatDate(user.updatedAt)}</td>
                      <td>{ROLES.find(role => role.value === user.role).text}</td>
                      <td>{STATUS[user.userStatus]}</td>
                      <td className="edit">
                        <a onClick={() => this.editUser(user)} className="edit-icon">
                          <span className="hover">Edit User</span>
                        </a>
                      </td>
                    </tr>
                  )
                }
              })}
            </tbody>
          ) : null}
        </Table>
        </form>
        <form onSubmit={this.validateNewUserForm}>
        <div id="new-user" className="new-user table-responsive">
          <table className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-xs-12">
            <tbody>
            {this.state.newUser ? (
              <tr>
                <td colSpan="5">
                  <div className="user-container user-info col-lg-5 col-md-5 col-sm-5 col-xs-5">
                    <div className="field-wrapper first-name">
                      <label htmlFor="user-status">First Name:</label>
                      <div className=" react-inputs-validation__textbox__wrapper___3tnXv false false false">
                        <div className="">
                          <input className="form-control" type="text" name="firstName" id="firstName" placeholder="Enter first name" value={this.state.firstName}
                                onChange={(e) =>{ if(e.target.value === '') this.setState({hasFirstNameError:true}); else this.setState({hasFirstNameError:false}); this.setState({'firstName': e.target.value})}}
                                onBlur={(e) =>{ if(e.target.value === '') this.setState({hasFirstNameError:true}); else this.setState({hasFirstNameError:false}); this.setState({'firstName': e.target.value})}}
                          />
                        </div>
                      </div>
                      {this.state.hasFirstNameError === true && (
                        <div className="error message">First Name is required</div>
                      )}
                    </div>
                    
                    <div className="field-wrapper last-name">
                      <label htmlFor="user-status">Last Name:</label>
                      <div className=" react-inputs-validation__textbox__wrapper___3tnXv false false false">
                        <div className="">
                          <input className="form-control" type="text" name="lastName" id="lastName" placeholder="Enter last name" value={this.state.lastName}
                                onChange={(e) =>{ if(e.target.value === '') this.setState({hasLastNameError:true}); else this.setState({hasLastNameError:false}); this.setState({'lastName': e.target.value})}}
                                onBlur={(e) =>{ if(e.target.value === '') this.setState({hasLastNameError:true}); else this.setState({hasLastNameError:false}); this.setState({'lastName': e.target.value})}}
                          />
                        </div>
                      </div>
                      {this.state.hasLastNameError === true && (
                        <div className="error message">Last Name is required</div>
                      )}
                    </div>

                    <div className="field-wrapper email">
                      <label htmlFor="user-status">Email Address:</label>
                      <div className=" react-inputs-validation__textbox__wrapper___3tnXv false false false">
                        <div className="">
                          <input className="form-control" type="text" name="email" id="email" placeholder="Enter email" value={this.state.email}
                                onChange={(e) =>{ if(e.target.value === '' || !email_reg.test(String(e.target.value).toLowerCase())) this.setState({hasEmailError:true}); else this.setState({hasEmailError:false}); this.setState({'email': e.target.value})}}
                                onBlur={(e) =>{ if(e.target.value === '' || !email_reg.test(String(e.target.value).toLowerCase())) this.setState({hasEmailError:true}); else this.setState({hasEmailError:false}); this.setState({'email': e.target.value})}}
                          />
                        </div>
                      </div>
                      {this.state.hasEmailError === true && (
                        <div className="error message">Email is required</div>
                      )}
                      {this.state.emailMessage !== "" &&
                        <div className="error message">Email is already in use</div>
                      }
                    </div>
                    <div className="field-wrapper role">
                      <label>Role:</label>
                      <select id="role" name="role" className="form-control" value={this.state.role} onChange={this.handleInputChange}>
                        {ROLES.map((role,key) => (
                          <option key={key} value={role.value}>{role.text}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="button-container col-lg-12 col-md-10 col-sm-6">
                    <button className="btn btn-success" onClick={this.newUserSubmit}>Add</button>&nbsp;
                    <Button variant="secondary" onClick={() => this.newUserCancel()}>Cancel</Button>{' '}
                  </div>
                </td>

              </tr>
            ) : (
                <tr><td className="add-user"><a onClick={() => this.addUser()}><span className="hover">Add User</span></a></td></tr>

              )}
            </tbody>
          </table>
        </div>
        </form>
      </div>
    );
  }
}
