import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import AuthService from "../../services/auth.service";

export default class Logout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    AuthService.logout();
    this.props.history.push("/?logout");
    window.location.reload();
  }

  render() {
    return null;
  }
}
