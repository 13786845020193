import React, { Component } from "react";
import { Button } from 'react-bootstrap';

import OrganizationDataService from "../services/organization.service";
import AnswerDataService from "../services/answer.service";
import AuthService from "../services/auth.service";

const PROCESS_DOMAIN = 18;

export default class Questionnaire extends Component {
  constructor(props) {
    super(props);
    this.toggleQuestion = this.toggleQuestion.bind(this);
    this.updateQuestionnaireState = this.updateQuestionnaireState.bind(this);
    this.getOrgInfo = this.getOrgInfo.bind(this);
    this.getCompletion = this.getCompletion.bind(this);

    this.state = {
      questionnaire: [],
      currentDomain: -1,
      currentUser: AuthService.getCurrentUser(),
      orgInfo: "",
      totalDomains: "",
      showMessage: true
    };
  }

  // runs on page's first load
  componentDidMount() {
    this.updateQuestionnaireState();
    this.getOrgInfo();
  }

  // gets the organization info of the current logged in user
  getOrgInfo() {
    OrganizationDataService.getOrgInfo({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          orgInfo: response.data.data[0],
        });
      })
  }

  // gets the number of completed domains
  getCompletion(){
    let data = {
      completed: 0,
      remaining: this.state.totalDomains
    };
    if(this.state.questionnaire){
      this.state.questionnaire.forEach(domain => {
        if(domain.percentComplete === 1){
          data.completed++;
        }
      });
    }
    data.remaining = this.state.totalDomains - data.completed;
    return data;
  }

  // submits the questionnaire
  submitQuestionnaire() {
    let orgInfo = this.state.orgInfo;
    let org = {
      id: orgInfo.id,
      submitted: true,
      submittedUser: this.state.currentUser.id
    }
    OrganizationDataService.submit(org)
      .then(response => {
        orgInfo.submitted = true;
        this.setState({
          orgInfo: orgInfo
        })
        this.props.history.push("/summary");
        window.location.reload();
      })
  }

  // gets the current state of the questionnaire (domains, questions, answers)
  updateQuestionnaireState() {
    OrganizationDataService.updateQuestionnaireState(this.state.currentUser.organizationId)
      .then(response => {
        let currentDomain = {};
        if(this.state.currentDomain === -1){
          currentDomain = response.data.data[0];
        }
        else{
          currentDomain = response.data.data.find(domain => domain.id === this.state.currentDomain.id);
        } 
        this.setState({
          questionnaire: response.data.data,
          totalDomains: response.data.data.length,
          currentDomain: currentDomain
        });
      })
  }

  // sets the active domain, called from navigation
  setActiveDomain(domain) {
    this.setState({
      currentDomain: domain,
    });
    window.scrollTo(0, 0);
  }

  // sets the next domain to be active
  loadNextDomain(){
    let index = this.state.questionnaire.findIndex(d => d.id === this.state.currentDomain.id);
    if(index < this.state.questionnaire.length){
      this.setActiveDomain(this.state.questionnaire[index + 1]);
    }
  }

  // sets the previous domain to be active
  loadPreviousDomain(){
    let index = this.state.questionnaire.findIndex(d => d.id === this.state.currentDomain.id);
    if(index > 0){
      this.setActiveDomain(this.state.questionnaire[index -1]);
    }
  }

  // updates or creates an answer's value
  toggleQuestion(question, value) {
    let answerId = null;
    if(question.answers.length > 0){
      answerId = question.answers[0].id
    }
    let answer = {
      id: answerId,
      domainId: question.domainId,
      questionId: question.id,
      value: value,
      organizationId: this.state.currentUser.organizationId
    }
    AnswerDataService.updateAnswer(answer)
      .then(response => {
        this.updateQuestionnaireState();
      })
  }

  //render  yes/no buttons
  renderButtons(question, type) {
    if(question.id === PROCESS_DOMAIN) type += " process";
    let value = 0;
    if(question.answers.length > 0){
      value = question.answers[0].value;
    }
    switch (value) {
      case 0:
        return (
          <div className={type + " question-wrapper"} data-capability-code={question.capabilityCode}>
            <p className={"question col-lg-8"}>{question.text}</p>
            <div className="yes-no-buttons col-lg-3">
              <a onClick={() => this.toggleQuestion(question, 1)}><Button variant="outline-secondary" className="cmm-yn button yes">YES</Button>{' '}</a>
              <a onClick={() => this.toggleQuestion(question, 2)}><Button variant="outline-secondary" className="cmm-yn button no">NO</Button>{' '}</a>
            </div>
          </div>
        );
      case 1:
        return (
          <div className={type + " question-wrapper"} data-capability-code={question.capabilityCode}>
            <p className={"question col-lg-8"}>{question.text}</p>
            <div className="yes-no-buttons col-lg-3">
              <a onClick={() => this.toggleQuestion(question, 1)}><Button variant="outline-secondary active" className="cmm-yn button yes">YES</Button>{' '}</a>
              <a onClick={() => this.toggleQuestion(question, 2)}><Button variant="outline-secondary" className="cmm-yn button no">NO</Button>{' '}</a>
            </div>
          </div>
        );
      case 2:
        return (
          <div className={type + " question-wrapper"} data-capability-code={question.capabilityCode}>
            <p className={"question col-lg-8"}>{question.text}</p>
            <div className="yes-no-buttons col-lg-3">
              <a onClick={() => this.toggleQuestion(question, 1)}><Button variant="outline-secondary" className="cmm-yn button yes">YES</Button>{' '}</a>
              <a onClick={() => this.toggleQuestion(question, 2)}><Button variant="outline-secondary active" className="cmm-yn button no">NO</Button>{' '}</a>
            </div>
          </div>
        );
      default:
        return 'error displaying question buttons';
    }
  }

  // render navigation buttons
  renderNavButton(domain, domainId) {
    let completion = domain.percentComplete;
    let className = "domain-tile";
    if (completion === 1) {
      className += " complete"
    }
    if (this.state.currentDomain.code === domain.code) {
      className += " active"
    }
    return (
      <li
        data-domain-code={domain.code}
        className={className}
        onClick={() => this.setActiveDomain(domain)}
        key={domainId}
      >
        <a>{domain.code}</a>
        <span className="complete-icon"></span>
        <span className="hover-title">{domain.name}</span>
      </li>
    )
  }

  // render component
  render() {
    // get completion for submit button
    let completion = this.getCompletion();

    return (
      <div className="cmmc qstnaire client-qstnaire client-view">
        {/* Navigation */}
        <div className="container-fluid domains col-xl-12 col-lg-12 col-md-2 col-sm-2 col-xs-1">
          <ul className="domain-menu">
            {this.state.questionnaire &&
              this.state.questionnaire.map((domain, domainId) => (
                this.renderNavButton(domain, domainId)
              ))}
          </ul>
          <div className="active-domain-title">
            <h3>{this.state.currentDomain.name}</h3>
          </div>
        </div>
        <div>
          {/* Question Area */}
          <div className={`container question-container ${this.state.currentDomain.code} col-xl-12 col-lg-12 col-md-10 col-sm-10 col-xs-10`}>
            {/* info button */}
            <div className="info-wrapper container">
              <div className="info-button"><p className="info-label">i</p>
                <div className="info-expanded">
                  <span className="tip 1">
                    <h5>{this.state.currentDomain.code} - {this.state.currentDomain.name}</h5>
                    <p><div dangerouslySetInnerHTML={{ __html: this.state.currentDomain.helpText }} /></p>
                  </span>
                </div>
              </div>
            </div>
            {/* first question */}
            {this.state.currentDomain.questions ?
              this.renderButtons(this.state.currentDomain.questions[0], "primary container") : null}
            {/* secondary questions */}
            {this.state.currentDomain.questions &&
              this.state.currentDomain.questions[0].domainId !== PROCESS_DOMAIN ?
              (
                <div className="secondary-questions container">
                  {this.state.currentDomain.questions[0].answers.length > 0 &&
                    this.state.currentDomain.questions[0].answers[0].value === 1 &&
                    this.state.currentDomain.questions.slice(1).map((question, questionId) => (
                      <div key={questionId}>
                        {this.renderButtons(question, "secondary")}
                      </div>
                    ))}
                </div>
              ) : (
                <div>
                  {this.state.currentDomain.questions &&
                    this.state.currentDomain.questions.slice(1).map((question, questionId) => (
                      <div key={questionId}>
                        {this.renderButtons(question, "primary container")}
                      </div>
                    ))}
                </div>
              )}
          </div>
        </div>
        {/* Submit Questionnaire */}
        {completion.completed === this.state.totalDomains && this.state.currentUser && this.state.currentUser.role === "fullcontrol" ? (
          <div className="button-container">
            <button type="button" className="btn btn-outline-primary btn-lg" onClick={()=>this.submitQuestionnaire()}>submit questionnaire</button>{' '}
          </div>

        ):

          this.state.showMessage === true ? (
            <div className="message friendly">
              <button onClick={()=>{this.setState({showMessage: false})}} className="close"></button>
              <h5>Remember: There are no right or wrong answers…</h5>

              <p>The questionnaire is designed to capture the cybersecurity things you have in place today compared to the
                CMMC model.</p>
            </div>
          ): null          
          
        }
        {/* Bottom Arrows */}
        <div className="pageination">
          {this.state.currentDomain !== -1 &&
            this.state.currentDomain.id > 1 ? (
              <div className="arrow-wrapper back col-xl-2 col-lg-1 col-md-1 col-sm-2 col-xs-2">
                <a className="back-arrow arrow"
                  onClick={() => this.loadPreviousDomain()}
                >
                  <span>BACK</span>
                </a>
              </div>
            ):null}
          {this.state.currentDomain !== -1 &&
            this.state.currentDomain.id < PROCESS_DOMAIN ? (
              <div className="arrow-wrapper next col-xl-2 col-lg-1 col-md-1 col-sm-2 col-xs-2">
                <a className="next-arrow arrow"
                  onClick={() => this.loadNextDomain()}
                >
                  <span>NEXT</span>
                </a>
              </div>
            ):null}
        </div>
      </div>
    );
  }
}
