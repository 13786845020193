import React, { Component } from "react";
import AuthService from "../../services/auth.service";
import constants from './../../constants.js'
import TermsofUse from "../termsofUse.component";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateForm = this.validateForm.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      organization: "",
      email: "",
      password: "",
      verifyPassword: "",
      terms: false,
      message: "",
      submitted: false,
      hasFirstNameError: true,
      hasLastNameError: true,
      hasOrganizationError: true,
      hasEmailError: true,
      hasPasswordError: true,
      hasTermError: true,
      passwordErrorMsg:'',
      hasVerifyPasswordError:true,
      validate: false,
    };
  }
  toggleValidating(validate) {
    this.setState({ validate });
  }

  validateForm(e) {
    const password_reg = constants.Password_Validation_Regex;
    const email_reg = constants.Email_Validation_Regex;

    e.preventDefault();
    this.toggleValidating(true);

    let validationErrors={};
    if(this.state.firstName.length === 0) {
      validationErrors.firstNameError = true;
    }
    if(this.state.lastName.length === 0) {
      validationErrors.lastNameError = true;
    }
    if(this.state.organization.length === 0) {
      validationErrors.organizationError = true;
    }
    if(this.state.email.length === 0 || !email_reg.test(String(this.state.email).toLowerCase())) {
      validationErrors.emailError = true;
    }
    if(this.state.password.length < 12 || !password_reg.test(String(this.state.password))) {
      validationErrors.passwordError = true;
    }
    if(this.state.password.length < 12 || this.state.verifyPassword !== this.state.password) {
      validationErrors.verifyPasswordError = true;
    }
    if(this.state.terms === false) {
      validationErrors.termError = true;
    }

    this.setState(validationErrors);
      
    return Object.keys(validationErrors).length;
      
  }

  componentDidMount() {

  }
  
  // handle input change
  handleInputChange(event) {
    const target = event.target;
    const value = target.name === 'terms' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  // handle login
  handleSubmit(e) {
    e.preventDefault();

    // validateForm returns the number of problems found.
    if(this.validateForm(e)) {
      return false;
    }
    
    this.setState({
      message: "",
      submitted: true
    });

    let data = {
      firstName: this.state.firstName,
      lastName:  this.state.lastName,
      organization:  this.state.organization,
      email:  this.state.email,
      password:  this.state.password
    }
    AuthService.register(data)
      .then(
        res => {
          this.setState({
            message: "Check your email for a confirmation link",
            submitted: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            message: resMessage
          });
        }
      );
  }

  renderForm() {
    const { validate, account, password, accountErrorMsg, passwordErrorMsg } = this.state;
    const password_reg = constants.Password_Validation_Regex;
    const email_reg = constants.Email_Validation_Regex;
    return (
      <form onSubmit={this.validateForm}>
      <div className="login-container register col-lg-7">
        <div className={`form-control ${(this.state.firstNameError === true )?'hasError':''}`}>
          <input type="text" name="firstName" placeholder="First Name"
                 onChange={(e) =>{ if(e.target.value === '') this.setState({firstNameError:true}); else this.setState({firstNameError:false}); this.setState({'firstName': e.target.value})}}
                 onBlur={(e) =>{ if(e.target.value === '') this.setState({firstNameError:true}); else this.setState({firstNameError:false}); this.setState({'firstName': e.target.value})}}
          />
          <div className="error message react-inputs-validation__msg_identifier">First Name is required</div>
        </div>
        <div className={`form-control ${(this.state.lastNameError === true )?'hasError':''}`}>
          <input type="text" name="lastName" placeholder="Last Name"
                 onChange={(e) =>{ if(e.target.value === '') this.setState({lastNameError:true}); else this.setState({lastNameError:false}); this.setState({'lastName': e.target.value})}}
                 onBlur={(e) =>{ if(e.target.value === '') this.setState({lastNameError:true}); else this.setState({lastNameError:false}); this.setState({'lastName': e.target.value})}}
          />
          <div className="error message react-inputs-validation__msg_identifier">Last Name is required</div>
        </div>
        <div className={`form-control ${(this.state.organizationError === true )?'hasError':''}`}>
          <input type="text" name="organization" placeholder="Organization"
                 onChange={(e) =>{ if(e.target.value === '') this.setState({organizationError:true}); else this.setState({organizationError:false}); this.setState({'organization': e.target.value})}}
                 onBlur={(e) =>{ if(e.target.value === '') this.setState({organizationError:true}); else this.setState({organizationError:false}); this.setState({'organization': e.target.value})}}
          />
          <div className="error message react-inputs-validation__msg_identifier">Organization is required</div>
        </div>
        <div className={`form-control username ${(this.state.emailError === true )?'hasError':''}`}>
          <input type="text" name="email" placeholder="Email"
                 onChange={(e) =>{ if(e.target.value === '' || !email_reg.test(String(e.target.value).toLowerCase())) this.setState({emailError:true}); else this.setState({emailError:false}); this.setState({'email': e.target.value})}}
                 onBlur={(e) =>{ if(e.target.value === '' || !email_reg.test(String(e.target.value).toLowerCase())) this.setState({emailError:true}); else this.setState({emailError:false}); this.setState({'email': e.target.value})}}
          />
          <div className="error message react-inputs-validation__msg_identifier">Please enter a valid email address</div>
        </div>
        <div className={`form-control password ${(this.state.passwordError === true )?'hasError':''}`}>
          <input type="password" name="password" placeholder="Password"
                 onChange={(e) =>{ 
                   if(e.target.value === ''
                     || e.target.value.length < 12
                     || !password_reg.test(String(e.target.value))) 
                     this.setState({passwordError:true}); 
                   else 
                     this.setState({passwordError:false}); 
                   this.setState({'password': e.target.value})}}
                 onBlur={(e) =>{ if(e.target.value === '' || !password_reg.test(String(e.target.value))) this.setState({passwordError:true}); else this.setState({passwordError:false}); this.setState({'password': e.target.value})}}
          />
          <div className="error message react-inputs-validation__msg_identifier">A valid password must be 12-20 characters in length, include at least one of each: a number, a lower case letter, an upper case letter, and a punctuation symbol</div>
        </div>
        <div className={`form-control password verify-password ${(this.state.verifyPasswordError === true )?'hasError':''}`}>
          <input type="password" name="verifyPassword" placeholder="Confirm Password"
                 onChange={(e) =>{
                   if(
                     e.target.value === ''
                     || e.target.value.length < 12
                     || e.target.value !== this.state.password
                   )
                     this.setState({verifyPasswordError:true});
                   else
                     this.setState({verifyPasswordError:false});
                   this.setState({'verifyPassword': e.target.value});
                 }}
                 onBlur={(e) =>{
                   if(e.target.value === ''
                     || e.target.value.length < 12
                     || e.target.value !== this.state.password
                   )
                     this.setState({verifyPasswordError:true});
                   else
                     this.setState({verifyPasswordError:false});
                   this.setState({'verifyPassword': e.target.value})
                 }}
          />
          <div className="error message react-inputs-validation__msg_identifier">Password and confirm password do not match</div>
        </div>
        <div className={`form-control terms-checkbox ${(this.state.termError === true )?'hasError':''}`}>
          <div className="checkbox-wrapper">
            <input type="checkbox" name="terms" value="true"
               onChange={(e) =>{
                 if(
                   e.target.checked === false
                 )
                   this.setState({termError:true});
                 else
                   this.setState({termError:false});
                 this.setState({'terms': e.target.checked});
               }}
               onBlur={(e) =>{
                 if(
                   e.target.checked === false
                 )
                   this.setState({termError:true});
                 else
                   this.setState({termError:false});
                 this.setState({'terms': e.target.checked});
               }}
            />
            <label>I agree to the <TermsofUse linkTitle="Terms of Use"/></label>
            <div className="error message react-inputs-validation__msg_identifier">You must agree to the terms of use</div>
          </div>
        </div>

          <div className="submit-button-wrapper">
              <button  className="btn btn-navy hover-blue btn-lg" onClick={this.handleSubmit}>Create Account</button>
          </div>
      </div>
      </form>
    );
  }
  // ...

  render() {
    return (
      <div className="cmmc qstnaire client-qstnaire client-view login ">
        <div className="fluid login">
          <div className="login-wrapper">
            {this.state.submitted === false ? (
                <div>
                  {this.renderForm()}
                </div>
              ):null}
            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
