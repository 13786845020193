import http from "../http-common";
import authHeader from './auth-header';

class OrganizationDataService {

  getOrgInfo(data) {
    return http.get("/organizations/"+ data.organizationId + "/info", { headers: authHeader() });
  }

  submit(data) {
    return http.put("/organizations/"+ data.id + "/submit", data, { headers: authHeader() });
  }

  getCompletionByOrg(data) {
    return http.get("/organizations/"+ data.organizationId + "/completion", { headers: authHeader() });
  }

  getLevelByOrg(data){
    return http.get("/organizations/"+ data.organizationId + "/level", { headers: authHeader() });
  }

  updateQuestionnaireState(organizationId){
    return http.get("/organizations/"+ organizationId + "/state", { headers: authHeader() });
  }

  getStarted(data){
    return http.put("/organizations/"+ data.organizationId + "/getStarted", data, { headers: authHeader() });
  }

}

export default new OrganizationDataService();
