import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

// use dotenv for environment variables
const dotenv = require('dotenv');
dotenv.config();

// render the app
ReactDOM.render( <App />, document.getElementById("root") );

serviceWorker.unregister();
