import http from "../http-common";

class AuthService {

  login(email, password) {
    return http
      .post("/auth/signin", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          sessionStorage.setItem("user", JSON.stringify(response.data));
        }
        return response;
      });
  }

  logout() {
    sessionStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(sessionStorage.getItem('user'));
  }

  isUserLoggedIn() {
    let user = sessionStorage.getItem('user');
    if (user === null) return false;
    return true;
  }

  register(data) {
    return http
      .post("/auth/register", data)
      .then(response => {
        return response;
      });
  }

  verifyEmail(userId, key) {
    return http
      .put("/auth/verify/"+userId+"/"+key)
      .then(response => {
        return response;
      });
  }

  forgotPassword(email){
    return http.put(`/auth/forgot`, email)
  }

}

export default new AuthService();