import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./cmmc.css";
import "./cmmc-addon.css";
import "./header-footer.css";
import "./response.css";
import "./users.css";


// custom route
import AuthenticatedRoute from './components/auth/authenticatedRoute.component'

// Auth Components
import Login from "./components/auth/login.component";
import Register from "./components/auth/register.component";
import Verify from "./components/auth/verify.component";
import ResetPassword from "./components/auth/resetPassword.component";
import ForgotPassword from "./components/auth/forgotPassword.component";
import Logout from "./components/auth/logout.component";

// pages
import Questionnaire from "./components/questionnaire.component";
import Summary from "./components/summary.component";
import Dashboard from "./components/dashboard.component";
import Responses from "./components/responses.component";
import Users from "./components/users.component";

// header/footer
import Header from "./components/header.component";
import Footer from "./components/footer.component";

class App extends Component {

  render() {
    return (
      <Router>
        <div className="page-container">
          <Header />
          <div className="content-container">
            <Switch>
                <Route exact path={["/login", "/"]} component={Login} />
                <Route exact path={["/logout"]} component={Logout} />
                <Route exact path={["/register"]} component={Register} />
                <Route exact path={["/verify/:userId/:key"]} component={Verify} />
                <Route exact path={["/reset/:userId/:key"]} component={ResetPassword} />
                <Route exact path={["/forgot/"]} component={ForgotPassword} />
      
                <AuthenticatedRoute exact path={"/questions"} component={Questionnaire} />
                <AuthenticatedRoute exact path={"/dashboard"} component={Dashboard} />
                <AuthenticatedRoute exact path={"/summary"} component={Summary} />
                <AuthenticatedRoute exact path={"/responses"} component={Responses} />
                <AuthenticatedRoute exact path={"/users"} component={Users} />
            </Switch>
          </div>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;
