import React, { Component } from "react";

import UserDataService from "../../services/user.service";
import constants from "../../constants";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRepeatPassword = this.onChangeRepeatPassword.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.checkLink = this.checkLink.bind(this);

    this.state = {
      userId: "",
      key: "",
      message: "",
      password: "",
      repeatPassword: "",
      hasPasswordError: true,
      hasVerifyPasswordError: true,
      validate: false,
      validLink: false
    };

    
    
  }

  toggleValidating(validate) {
    this.setState({ validate });
  }
  
  validateForm(e) {
    e.preventDefault();
    this.toggleValidating(true);
    const {
      passwordError,
      verifyPasswordError,
    } = this.state;
    if (
      !passwordError &&
      !verifyPasswordError &&
      this.state.password !== ''
    ) {
      this.setState({
        message: "",
        submitted: true
      });

      let data = {
        password:  this.state.password
      }
      
      this.updatePassword();
    }
    else {
      this.setState({passwordError:true, verifyPasswordError:true})
    }
  }

  componentDidMount() {
    this.setState({
      userId: this.props.match.params.userId,
      key: this.props.match.params.key,
    });
    this.checkLink();
  }

  checkLink(){
    let data = {
      userId: this.props.match.params.userId,
      key: this.props.match.params.key,
    }
    UserDataService.checkResetLink(data)
    .then(
      res => {
        this.setState({
          validLink: true
        })
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          message: resMessage
        });
      }
    );
  }

  // change password
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  // change repeat password
  onChangeRepeatPassword(e) {
    this.setState({
      repeatPassword: e.target.value
    });
  }

  updatePassword() {
    let user = {
      id: this.state.userId,
      key: this.state.key,
      password: this.state.password
    }
    UserDataService.updatePassword(user)
      .then(
        res => {
          this.props.history.push("/login?reset");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            message: resMessage
          });
        }
      );
  }

  // ...

  render() {
    const { validate, account, password, accountErrorMsg, passwordErrorMsg } = this.state;
    const password_reg = constants.Password_Validation_Regex;

    return (
      <div className="cmmc qstnaire client-qstnaire client-view login ">
        <div className="container fluid login">
          <div className="login-wrapper">
            <div className="cmmc-logo-wrapper"></div>
            <div className="login-container col-lg-7">
              {this.state.message && (this.state.message != "") ? (
                <div className="form-group">
                  <div className="alert alert-secondary" role="alert">
                    {this.state.message}
                  </div>
                </div>
              ) : null}
              {this.state.validLink ? (
                <form onSubmit={this.validateForm}>
                Please enter a new password and click the button to submit.
                  <div className={`form-control password ${(this.state.passwordError === true )?'hasError':''}`}>
                    <input type="password" name="password" placeholder="Password"
                           onChange={(e) =>{
                             if(e.target.value === ''
                               || e.target.value.length < 12
                               || !password_reg.test(String(e.target.value)))
                               this.setState({passwordError:true});
                             else
                               this.setState({passwordError:false});
                             this.setState({'password': e.target.value})}}
                           onBlur={(e) =>{ if(e.target.value === '' || !password_reg.test(String(e.target.value))) this.setState({passwordError:true}); else this.setState({passwordError:false}); this.setState({'password': e.target.value})}}
                    />
                    <div className="error message react-inputs-validation__msg_identifier">A valid password must be 12-20 characters in length, include at least one of each: a number, a lower case letter, an upper case letter, and a punctuation symbol</div>
                  </div>
                  <div className={`form-control password verify-password ${(this.state.verifyPasswordError === true )?'hasError':''}`}>
                    <input type="password" name="verifyPassword" placeholder="Confirm Password"
                           onChange={(e) =>{
                             if(
                               e.target.value === ''
                               || e.target.value.length < 12
                               || e.target.value !== this.state.password
                             )
                               this.setState({verifyPasswordError:true});
                             else
                               this.setState({verifyPasswordError:false});
                             this.setState({'verifyPassword': e.target.value});
                           }}
                           onBlur={(e) =>{
                             if(e.target.value === ''
                               || e.target.value.length < 12
                               || e.target.value !== this.state.password
                             )
                               this.setState({verifyPasswordError:true});
                             else
                               this.setState({verifyPasswordError:false});
                             this.setState({'verifyPassword': e.target.value})
                           }}
                    />
                    <div className="error message react-inputs-validation__msg_identifier">Password and confirm password do not match</div>
                  </div>
                <div className="submit-button-wrapper">
                  <button onClick={this.validate} className="btn btn-navy hover-blue btn-lg">Update Password</button>
                </div>
                </form>
              ):null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
