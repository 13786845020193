
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import logo from '../assets/hka-logo-small-white.png';
import AuthService from "../services/auth.service";
import OrganizationDataService from "../services/organization.service";
import GetAssistance from "./getAssistance.component";
import {withRouter} from "react-router";
import PropTypes from "prop-types";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.getOrgInfo = this.getOrgInfo.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser()
    };
  }

  componentDidMount() {
    if(this.state.currentUser !== null) this.getOrgInfo();
  }

  getOrgInfo() {
    OrganizationDataService.getOrgInfo({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          orgInfo: response.data.data[0],
        });
      })
  }

  render() {
    return (
      <div>
        {this.state.currentUser !== null ?
          <Navbar collapseOnSelect expand="lg" className="color-nav" variant="dark" fixed="top">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Navbar.Brand href="https://www.hka.com" className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <img src={logo} className="d-inline-block align-top img-responsive" />
              </Navbar.Brand>
              <Nav className="mr-auto"></Nav>
              <Nav className="col-lg-9 col-md-6 col-sm-4 col-xs-4">
                <GetAssistance/>
                <a href="/dashboard" className="home-nav-link">
                  <span className="hover">Home</span>
                </a>
                <NavDropdown title={this.state.currentUser.name} id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        :
        (
          <Navbar collapseOnSelect expand="lg" className="color-nav" variant="dark" fixed="top">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Navbar.Brand href="https://www.hka.com" className="col-lg-3 col-md-3 col-sm-3 col-xs-3">
                <img src={logo} className="d-inline-block align-top img-responsive" />
              </Navbar.Brand>
            </Navbar.Collapse>
          </Navbar>
        )}
        <div className="header">
          <div className="client-logo col-lg-3">
          {this.state.orgInfo ? (
            <h1>{this.state.orgInfo.name}</h1>
          ):null}
            
          </div>
          <div className="page-header">
            <ShowTheTitleWithRouter/>
          </div>
        </div>
      </div>
    )
  }
}



class ShowTheTitle extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };


  getPageTitle() {
    let title = "CMMC Questionnaire"
    let page = window.location.pathname;
    if(page === "/dashboard") title = "CMMC Questionnaire";
    if(page === "/questions") title = "Questionnaire";
    if(page === "/summary") title = "Questionnaire Results";
    if(page === "/responses") title = "Questionnaire Responses";
    if(page === "/users") title = "User Management";

    return title;
  }

  render() {
    const { match, location, history } = this.props;

    return <h2>{this.getPageTitle()}</h2>;
  }
}

// Create a new component that is "connected" (to borrow redux
// terminology) to the router.
const ShowTheTitleWithRouter = withRouter(ShowTheTitle);