import React, { Component } from "react";
import OrganizationDataService from "../services/organization.service";
import UserDataService from "../services/user.service";

import AuthService from "../services/auth.service";

const PROCESS_DOMAIN_ID = 18; // added here until db is fixed with a flag

export default class Responses extends Component {
  constructor(props) {
    super(props);
    this.updateQuestionnaireState = this.updateQuestionnaireState.bind(this);
    this.checkStarted = this.checkStarted.bind(this);

    this.state = {
      domains: [],
      currentUser: AuthService.getCurrentUser(),
      submittedUser: "",
      submittedDate: "",
      questionnaire: []
    };

    this.getMaturityLevel();
  }

  componentDidMount() {
    this.getOrgInfo();
    this.updateQuestionnaireState();
  }

  getMaturityLevel() {
    OrganizationDataService.getLevelByOrg({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          maturity: response.data.data.currentLevel,
          levels: response.data.data
        });
      })
  }

  getOrgInfo() {
    OrganizationDataService.getOrgInfo({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        let utcDate = response.data.data[0].updatedAt;
        let localDate = new Date(utcDate);
        let dateString = (localDate.getMonth()+1)+"/"+localDate.getDate()+"/"+localDate.getFullYear();
        this.setState({
          orgInfo: response.data.data[0],
          submittedDate: dateString,
        });
        if(response.data.data[0].submitted) this.getUserInfo();
      })
  }

  getUserInfo() {
    UserDataService.getUser(this.state.orgInfo.submittedUser)
      .then(response => {
        let user = response.data.data
        this.setState({
          submittedUser: user.firstName + " " + user.lastName
        });
      })
  }

  // gets the current state of the questionnaire (domains, questions, answers)
  updateQuestionnaireState() {
    OrganizationDataService.updateQuestionnaireState(this.state.currentUser.organizationId)
      .then(response => {
        this.setState({
          questionnaire: response.data.data,
        });
      })
  }

  // render question value
  renderAnswer(value) {
    switch (value) {
      case 0:
        return <span className="status-no-answer">No answer</span>;
      case 1:
        return <span className="status-yes">Yes</span>
      case 2:
        return <span className="status-no">No</span>
      default:
        return 'error displaying question buttons';
    }
  }

  // returns true if the questionnaire has been started
  checkStarted(){
    if(this.state.questionnaire){
      for(let domain of this.state.questionnaire){
        for(let question of domain.questions){
          if(question.answers && question.answers.length > 0){
            return true;
          }
        }
      };
    }
    return false;
  }

  // ...
  render() {

    // process domain
    let processDomain = this.state.questionnaire.find(d => d.id === PROCESS_DOMAIN_ID);

    return (
      <div className="row"><div className="col-md-12">
        <div className="cmm">
          <div className="container-fluid self-assess-portal sap view-resp-sub-docs vrsd client-view">
            {!this.checkStarted() && this.state.questionnaire.length !== 0 ? (
              <div className="content-wrap cmm-page-title no-print">
                <h5>No questionnaire data input.</h5>
                {this.state.currentUser.role != "readonly" &&
                  <a href="/questions">Click here to access the questionnaire.</a>
                } 
              </div>
            ):(
            <div className="content-wrap">
              <div className="cmm-page-title print-only">
              {this.state.orgInfo ? (
                <h1>CMMC Questionnaire Responses</h1>
                ):null}
              </div>
              <div className="cmm-page-title print-only">
              {this.state.orgInfo ? (
                <h1>{this.state.orgInfo.name}</h1>
                ):null}
              </div>
              {this.state.orgInfo && this.state.orgInfo.submitted === true ? (
              <div className="cmm-page-title">
                  <h5>Preliminary Maturity Level: {this.state.maturity > 0 ? `Level ${this.state.maturity}` : "Pre-Level 1"}</h5>
                  <span className="quest-submission-info tiny-text">Questionnaire submitted on {this.state.submittedDate} by {this.state.submittedUser}</span>
              </div>
              ):null}
              <button className="report-button no-print" onClick={() => window.print()}>PRINT</button>
              <div className="main-content container domain-resp divContent">
                {this.state.questionnaire &&
                  this.state.questionnaire.map((domain, id) => (
                    <div key={id}>
                      {domain.id !== PROCESS_DOMAIN_ID ? (
                      <ul className="domain-1">
                        <span className="number">{domain.id}</span>
                        {domain.questions && domain.questions.length > 0 &&
                          domain.questions.map((question, id) => 
                          (
                            question.parentId === null ? (
                              <li className="domain-title" key={id}> {domain.name}:
                                {question.answers && question.answers.length > 0 && question.answers[0].value === 1 ? 
                                  ( <span className="status-yes">Yes, controls are implemented</span> )
                                : question.answers && question.answers.length > 0 && question.answers[0].value === 2 ? 
                                  ( <span className="status-no">No, controls are not implemented</span> )
                                : ( <span className="status-no-answer">No answer</span> )
                                }
                              </li>
                            ) : (
                              domain.questions[0].answers.length > 0 && domain.questions[0].answers[0].value === 1 ? (
                                <li className="sub-quest" key={id}>
                                  {question.text} {question.answers.length > 0 ? (
                                    this.renderAnswer(question.answers[0].value)
                                  ):(
                                    this.renderAnswer(0)
                                  )}
                                </li>
                              ):null
                            )
                          ))}                     
                      </ul>
                      ):(
                      <ul className="domain-1">
                        <span className="number">{domain.id}</span>
                        <li className="domain-title" key={id}> {domain.name}</li>
                        {domain.questions && domain.questions.length > 0 &&
                          domain.questions.map((question, id) => (
                            question.parentId === null ? (
                              <li className="sub-quest" key={id}>
                                  {question.text} {question.answers.length > 0 ? (
                                    this.renderAnswer(question.answers[0].value)
                                  ):(
                                    this.renderAnswer(0)
                                  )}
                                </li>
                              ):null
                          ))}
                      </ul>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
      </div>
    );
  }
}
