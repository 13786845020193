import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PrivacyStatement from "./privacyStatement.component";
import CookieNotice from "./cookieNotice.component";
import PropTypes from "prop-types";

const TermsofUse = ({ linkTitle }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a href="#" onClick={handleShow}>
        { linkTitle }
      </a>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms of Use</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="page-wrap">
            <div  class="primary_layout">
              <div class="mainpage-wrapper termsofUse">
                <p>Last revised: June 16, 2020</p>
                <h4>Introduction</h4>
                <p>These Terms of Use apply to NorthcrossGroup.com or the NCG practice specific website that you were viewing before clicking on these Terms of Use. Such individual website is referred to in these Terms of Use as "<span class="bold">this Website</span>."  Northcross Group is providing this Website and is referred to in these Terms of Use as "<span class="bold">we</span>," "<span class="bold">us</span>," or "<span class="bold">our</span>."
                </p>
                <p><span class="bold">By using this Website, you are agreeing to these Terms of Use. If you do not agree to these Terms of Use, then you are not allowed to use this Website and should immediately terminate such usage.</span>
                </p>
                <h4>Use of Content; Restrictions; Privacy Statement</h4>
                <p>Unless otherwise indicated in the relevant content, and on the condition that you comply with all of your obligations under these Terms of Use, you are authorized to view, copy, print, and distribute (but not modify) the content on this Website; provided that (i) such use is for informational, noncommercial purposes only, and (ii) any copy of the content that you make must include the copyright notice or other attribution associated with the content.
                </p>
                <p>You are not authorized to copy or use any software, proprietary processes, or technology embodied or described in this Website.
                </p>
                <p>You will comply with all applicable laws in accessing and using this Website.
                </p>
                <p>You acknowledge that we may use your personal information and data and any confidential information you provide to us according to our Privacy Statement and its accompanying Cookie Notice, which are incorporated herein by this reference.  By using this Website, you are also agreeing to the terms of our <PrivacyStatement/> and <CookieNotice/>, including any obligations imposed on you therein.
                </p>
                <h4>Intellectual Property Rights; No use of Northcross Group names or logos</h4>
                <p>Unless otherwise indicated, the content on this Website is provided by us.  This Website and its contents are protected by copyright, trademark, and other laws of the United States and/or foreign countries. We and our licensors reserve all rights not expressly granted in these Terms of Use.
                </p>
                <p>“Northcross Group,” “NCG,” the NCG logo, the Northcross Group logo, and local language variants of the foregoing trademarks, and certain product names that appear on this Website (collectively, the “Northcross Group Marks”), are trademarks or registered trademarks of Northcross Group. Except as expressly provided in these Terms of Use or as expressly authorized in writing by the relevant trademark owner, you shall not use any Northcross Group Marks either alone or in combination with other words or design elements, including, in any press release, advertisement, or other promotional or marketing material or media, whether in written, oral, electronic, visual, or any other form.
                </p>
                <p>References to other parties trademarks on this Website are for identification purposes only and do not indicate that such parties have approved this Website or any of its contents. These Terms of Use do not grant you any right to use the trademarks of other parties.
                </p>
                <h4>Disclaimers and Limitations of Liability</h4>
                <p>THIS WEBSITE (INCLUDING, WITHOUT LIMITATION, ANY CONTENT OR OTHER PART THEREOF) CONTAINS GENERAL INFORMATION ONLY, AND WE ARE NOT, BY MEANS OF THIS WEBSITE, RENDERING PROFESSIONAL ADVICE OR SERVICES. BEFORE MAKING ANY DECISION OR TAKING ANY ACTION THAT MIGHT AFFECT YOUR FINANCES OR BUSINESS, YOU SHOULD CONSULT A QUALIFIED PROFESSIONAL ADVISOR.
                </p>
                <p>THIS WEBSITE IS PROVIDED AS IS, AND WE MAKE NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES REGARDING IT. WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT THAT THIS WEBSITE WILL BE SECURE, ERROR-FREE, FREE FROM VIRUSES OR MALICIOUS CODE, OR WILL MEET ANY PARTICULAR CRITERIA OF PERFORMANCE OR QUALITY. WE EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, COMPATIBILITY, SECURITY, AND ACCURACY.
                </p>
                <p>YOUR USE OF THIS WEBSITE IS AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USAGE, INCLUDING, WITHOUT LIMITATION, WITH RESPECT TO LOSS OF SERVICE OR DATA. WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES WHATSOEVER, WHETHER IN AN ACTION OF CONTRACT, STATUTE, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR OTHERWISE, RELATING TO OR ARISING OUT OF THE USE OF THIS WEBSITE, EVEN IF WE KNEW, OR SHOULD HAVE KNOWN, OF THE POSSIBILITY OF SUCH DAMAGES.
                </p>
                <p>CERTAIN LINKS ON THIS WEBSITE MAY LEAD TO WEBSITES, RESOURCES, OR TOOLS MAINTAINED BY THIRD PARTIES OVER WHOM WE HAVE NO CONTROL. WITHOUT LIMITING ANY OF THE FOREGOING, WE MAKE NO EXPRESS OR IMPLIED REPRESENTATIONS OR WARRANTIES WHATSOEVER REGARDING SUCH WEBSITES, RESOURCES, AND TOOLS, AND LINKS TO ANY SUCH WEBSITES, RESOURCES, AND TOOLS SHOULD NOT BE CONSTRUED AS AN ENDORSEMENT OF THEM OR THEIR CONTENT BY US.
                </p>
                <p>THE ABOVE DISCLAIMERS AND LIMITATIONS OF LIABILITY ARE APPLICABLE TO THE FULLEST EXTENT PERMITTED BY LAW, WHETHER IN CONTRACT, STATUTE, TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), OR OTHERWISE.
                </p>
                <h4>Additional Terms</h4>
                <p>If any portion of these Terms of Use is invalid or unenforceable in any jurisdiction, then (i) in that jurisdiction it shall be re-construed to the maximum effect permitted by law in order to effect its intent as nearly as possible, and the remainder of these Terms of Use shall remain in full force and effect, and (ii) in every other jurisdiction, all of these Terms of Use shall remain in full force and effect.
                </p>
                <p>We may revise these Terms of Use at any time in our sole discretion by posting such revised Terms of Use at the Terms of Use link (i.e., this webpage that you are currently viewing) or elsewhere in this Website. Such revisions shall be effective as to you upon posting, unless explicitly stated by us. It is your responsibility to be aware of any such revised Terms of Use by checking this webpage. Your continued use of this Website following changes to these Terms of Use constitutes your agreement to the revised Terms of Use.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

TermsofUse.propTypes = {
  linkTitle: PropTypes.string.isRequired
};

// Set a default value for any required props.
TermsofUse.defaultProps = {
  linkTitle: 'Terms of Use',
};

export default TermsofUse;
