import React, { Component } from "react";

import AuthService from "../../services/auth.service";

export default class Verify extends Component {
  constructor(props) {
    super(props);
    this.verifyKey = this.verifyKey.bind(this);

    this.state = {
      userId: "",
      key: "",
      message: "Verifying key",
      verified: false
    };
  }

  componentDidMount() {
    this.setState({
      userId: this.props.match.params.userId,
      key: this.props.match.params.key,
    }, () => {
      this.verifyKey();
    });
   
  }

  verifyKey(){
    AuthService.verifyEmail(this.state.userId, this.state.key)
      .then(
        res => {
          this.setState({
            verified:true,
            message: "Your email has been verified successfully."
          });
          this.props.history.push("/login?verify");
          window.location.reload();
        },
        error => {
          this.props.history.push("/login?invalid");
          window.location.reload();
        }
      );

  }
  // ...

  render() {
    return (
      <div>
      </div>
    );
  }
}
