import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import UnderstandingResults from "./understandingResults.component.js";

import imgQuestionnaireIcon from '../assets/getAssistance/questionnaireIcon.png';
import imgSummaryPage from '../assets/getAssistance/summaryPage.png';
import imgNextStepsIcon from '../assets/getAssistance/nextStepsIcon.png';
import imgNextSteps from '../assets/getAssistance/nextSteps.png';
import imgManageUsers from '../assets/getAssistance/manageUsers.png';
import imgAddUser from '../assets/getAssistance/addUser.png';
import imgAddUserForm from '../assets/getAssistance/addUserForm.png';
import imgEditUser from '../assets/getAssistance/editUser.png';
import imgSaveUserInfo from '../assets/getAssistance/saveUserinfo.png';
import imgAdditionalQuestions from '../assets/getAssistance/additionalQuestions.png';
import imgViewResponses from '../assets/getAssistance/viewResponses.png';
import imgResponses from '../assets/getAssistance/responses.png';
import imgInfo from '../assets/getAssistance/info.png';
import imgDomainNav from '../assets/getAssistance/domains.png';
import imgNextBackArrows from '../assets/getAssistance/nextBackarrows.png';
import imgDomainStatus from '../assets/getAssistance/domainStatus.png';
import imgSubmitHomepage from '../assets/getAssistance/submitHomepage.png';
import imgSubmitQuestionnaire from '../assets/getAssistance/submitQuestionnaire.png';


function GetAssistance() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a href="#" className="get-assistance"  onClick={handleShow}>
        <p>?</p>
        <span className="hover">Get Assistance</span>
      </a>


      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get Assistance</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <a href="#complete_questionnaire" className="assistance-section">Completing the CMMC Questionnaire</a>
          <a href="#viewing_progress" className="assistance-section">Viewing Progress: Responses and Submitted Docs</a>
          <a href="#manage_users" className="assistance-section">Creating and Updating Users to help you complete the CMMC Questionnaire</a>
          <a href="#view_results" className="assistance-section">Viewing your CMMC Questionnaire Results</a>
          <UnderstandingResults/>
          <hr/>
          <a name="complete_questionnaire"/>
          <h1>Completing the CMMC Questionnaire</h1>
          <p>The first time you want to enter data into the Questionnaire, click on the Questionnaire Input icon.</p>

          <img src={imgQuestionnaireIcon}/>
          
          <p>You can return to the Questionnaire as often as you need (please see <a href="#viewing_progress">Viewing Progress: Responses and
            Submitted Docs</a>). The Questionnaire icon label will then read “Complete Questionnaire” and show your
            progress in completing the questionnaire.</p>
                    
          <h2>Navigating Through the Questionnaire questions:</h2>
          <ul>
            <li>The Questionnaire data input page lists the 17 CMMC Domains across the top of the screen, and
            then a question about the domain that is currently selected is displayed below the list.
          
            <img src={imgDomainNav}/>
            </li>
          
            <li>The last item in the listing is a set of questions about Processes under the CMMC model.</li>
            <li>Incomplete Domains will display grey. When a Domain is active, it will display in a white. Once a domain is completed, it will display in blue with a green checkmark.

              <img src={imgDomainStatus}/>
            </li>
            <li>The list of Domains is a navigation control that allows you to jump back and forth to any domain.</li>
            <li>You can also move between domain questions by clicking the arrows on the far left and right
              sides of your screen.
              <img src={imgNextBackArrows}/>
            </li>
          </ul>
          <h2>Entering Questionnaire Data:</h2>
          <ul>
            <li>Under each CMMC Domain, a basic question asks whether your organization is practicing efforts
              under that domain.</li>
            <li>If you answer yes to that question, additional questions will be presented to better understand
              the specific capabilities your organization has under this Domain.
          
              <img src={imgAdditionalQuestions}/></li>
            <li> Hover over the info icon to the left of the first question to display information about the domain.
              <img src={imgInfo}/></li>
            <li>If you answer No to a Domain question, you click the right “Next” arrow on the far side of the
              screen or select another Domain from the list at the top of the page.</li>
            <li> The button labeled “Processes” asks three questions about what general cybersecurity
              processes your organization has implemented.</li>
          </ul>
          <h2>Completing the Questionnaire:</h2>
          <ul>
            <li>Once you have entered a response for each of the Domains, Processes, and all the questions
              under a Domain that you are practicing, a “Submit Questionnaire” button is available on the
              Questionnaire data input page and on the CMMC Orchestration Questionnaire home page.</li>
            <li> When you are satisfied with all your responses, hit the “Submit Questionnaire” button to submit
              your data and receive your results immediately.
            </li>
            <li className="two-column-images">
              <img src={imgSubmitQuestionnaire} className="submit-ref-image"/>
              <img src={imgSubmitHomepage} className="submit-ref-image"/>
            </li>
          </ul>
          <a name="viewing_progress"/>
          <h1>Viewing Progress: Responses and Submitted Docs</h1>
          <p>You can return to the questionnaire as often as needed to complete it. You can review your previous
          input either through the questionnaire data input page or by clicking the View Responses icon for read
            only/print friendly view.</p>
          <p>Once you have submitted the questionnaire, you can still view the input from the View Responses page.</p>
          <ul>
            <li> Clicking on the View Responses icon takes you to a page that lists all your responses submitted
                to date, organized by Domains and then Processes.
                <img src={imgViewResponses}/>
            </li>
          
            <li> A print button is provided on the top far right corner of the screen to print out a copy of the
                questionnaire results.
                <img src={imgResponses}/>
            </li>
          </ul>
          <a name="manage_users"/>
          <h1>Creating and Updating Users to Complete the CMMC Questionnaire</h1>
          <p>You can add or edit users if you need assistance completing the questionnaire.</p>
          <p>To begin adding or editing users, click the Manage Users icon located on the CMMC Orchestration
            Questionnaire home page. Clicking on the Manage Users icon takes you to a page that lists all registered
            users for your organization by names, last active dates, roles, and statuses.</p>
          <img src={imgManageUsers}/>
          <h2>Adding New Users:</h2>
          <ul>
            <li>If you would like to add a new user to the organization, you can click the Add User icon below
              the list of registered users.
          
              <img src={imgAddUser}/>
            </li>
          
            <li>When clicked on, the Add User icon will display fields to input information for the new user and
              an Add button that will save the new user in the system. The user status will default to inactive
              until the user verifies their account via the email link.
          
              <img src={imgAddUserForm}/>
            </li>
          
          <li> There are 3 types of roles that can be assigned to a user for an organization. The Full Control
            role will give the user full editing and viewing permissions for their organization’s questionnaire
            input, user management, and results. The Questionnaire Only role will give the user permission
            to edit and view questions as well as view the results of the questionnaire. The Read Only role
            will give the user access to view the results of the questionnaire only.</li>
          </ul>
          <h2>Editing Registered User Information:</h2>
          <ul>
            <li>User information can be edited by clicking the Edit User icon on the far-right column of the
              Manage Users page. The Edit User icon takes you to the Edit User page that allows you to
              change the name, role, and status of a user.
          
              <img src={imgEditUser}/>
            </li>
            <li>If there is a single user registered to the organization, the only editable fields will be first and
              last name. One active full control user for the organization is always required.</li>
            <li>Once you’ve made all the changes you need to, click the Save button at the bottom of the
              screen to save your changes. You may need to close the tab and log back in to refresh the fields.
              <img src={imgSaveUserInfo}/>
            </li>
          </ul>
          <h1>Resetting an Existing User’s Password</h1>
          <p>You can also reset a password for a registered user on the Edit User page. If you or another user in
            the organization would like to reset their password, you can hit the Reset Password button on the
            Edit User page for the user that wants to change their password. A reset password link will be sent
            to that user’s email.</p>
          <a name="view_results"/>
          <h1>Viewing Your CMMC Questionnaire Results</h1>
          <p>You can review your questionnaire results and recommended next steps post-submission at any time in
            a read only/print friendly view.</p>
          <p>To view your projected maturity level and next steps, click the View Questionnaire Results &amp; Next Steps
            icon on the CMMC Orchestration Questionnaire home page.
            <img src={imgNextStepsIcon}/>
          </p>
          <h2>Viewing Projected Questionnaire Results:</h2>
          <ul>
            <li>Your projected maturity level based on the questionnaire results will be displayed on the first
              line of the Preliminary Maturity Level page. The maturity level displayed is a projection based on
              your questionnaire responses for domain, capability, and process questions.</li>
            <li> The Capabilities field displays the number of capabilities you have reported for your
              organization that includes both capabilities that drive the projected Maturity Level, plus any
              additional ones that would be working towards a subsequent Level.</li>
            <li> The Processes field lists the types of processes required for Maturity Levels 2-5 that you have
              reported for your organization.
            
              <img src={imgSummaryPage}/>
            </li>
          </ul>
 
          <h2>Getting Started with Next Steps:</h2>
          <ul>
            <li>The recommended next steps based on your results can be found beneath the results of the
              questionnaire on the Preliminary Maturity Level page.
            <img src={imgNextSteps}/>
            </li>
            <li>If you would like to get started with the recommended next steps, click the Get Started button
              located at the bottom of the Preliminary Maturity Level page.</li>
            <li> You should expect an email from an account representative within one business day to
              get started.</li>
          </ul>
          <p>For additional support, please contact
            <a href="mailto:info@cmmcquestionnaire.com"> info@cmmcquestionnaire.com</a>
          </p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GetAssistance;