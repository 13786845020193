import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PrivacyStatement from "./privacyStatement.component";
function CookieNotice() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a href="#" onClick={handleShow}>
        Cookie Notice
      </a>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cookie Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mainpage-wrapper cookieNotice">
            <p>Last revised: June 16, 2020</p>
            <p>This Website uses cookies to improve the user experience and ensure that it is functioning effectively.
              This Cookie Notice is part of our <PrivacyStatement/>. For more information about us, and how we protect visitor information, please see our <PrivacyStatement/>.
            </p>
            <p>In order to provide you with a more personalized and responsive service we need to remember and store
              information about how you use this Website. This is done using small text files called cookies. Cookies
              contain small amounts of information and are downloaded to your computer or other device by a server for
              this Website. Your web browser then sends these cookies back to this Website on each subsequent visit so
              that it can recognize you and remember things like your user preferences. You can find more detailed
              information about cookies and how they work at http://www.aboutcookies.org/.
            </p>
            <p>Whenever you use this Website, information may be collected through the use of cookies and other
              technologies. By using this Website you agree to our use of all such technologies, as described in this
              Cookie Notice.
            </p>
            <h4>What cookies do we use and why?</h4>
            <p>Some of the cookies we use are necessary to enable you to move around the Website and use its features
              such as accessing secure areas that may contain content for registered users.
            </p>
            <p>We also use functional cookies to record information about the choices you have made and to allow us to
              tailor this Website to our users; for example, to remember your language or region or that you have
              already completed a survey. This information is usually anonymized and is not used for any purpose
              unrelated to this Website.
            </p>
            <p>We or our service providers also use analytic services to help us understand how effective our content
              is, what interests our users have, and to improve how this Website works. In addition, we use web beacons,
              tracking pixels and similar services to understand more about the visitors to this Website, and to count
              visitor numbers and performance cookies to track how many individual users access this Website and how
              often. This information is used for statistical purposes and to improve how this Website works and it is
              not our intention to use such information to personally identify any user. However, if at any time you
              register and sign into this Website we may combine such data with information from our web analytic
              services and cookies to analyze how you use this Website in more detail. This Website does not use
              targeting cookies to deliver targeted advertising on this Website.
            </p>
            <h4>How to control cookies?</h4>
            <p>By using this Website, you agree that we can place cookies on your computer or device as explained above.
              However, you can control and manage cookies in various ways. Please bear in mind that removing or blocking
              cookies can impact your user experience, and parts of this Website may no longer be fully accessible.
            </p>
            <p>If you do not accept the use of these cookies please disable them by changing your browser settings so
              that cookies from this Website cannot be placed on your computer or mobile device.
            </p>
            <h4>Email communications</h4>
            <p>We may also use tracking technologies to determine whether you have read, clicked on, or forwarded
              certain email communications we send to you so that we can make our communications more helpful and
              interesting. If you do not wish us to confirm whether you have opened, clicked on or forwarded our
              communications, you will need to unsubscribe, as it is not possible for us to send these emails without
              tracking enabled. Registered subscribers can update their communication preferences at any time by <a
                href="mailto:info@cmmcquestionnaire.com">contacting us</a>, or you can unsubscribe following the instructions in the individual
              email communications you receive from us.
            </p>
            <p>We may modify or amend this Cookie Notice from time to time at our discretion. When we make changes to
              this notice, we will amend the revision date at the top of this page, and such modified or amended Cookie
              Notice shall be effective as to you and your information as of that revision date. We encourage you to
              periodically review this Cookie Notice to be informed about how we are using cookies.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CookieNotice;