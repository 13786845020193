import http from "../http-common";
import authHeader from './auth-header';

class AnswerDataService {
  getAllByDomain(id, organizationId) {
    return http.get(`/answers/domain/${id}/${organizationId}`, { headers: authHeader() });
  }

  updateAnswer(answer) {
    return http.put(`/answers/${answer.id}`, answer, { headers: authHeader() });
  }

}

export default new AnswerDataService();
