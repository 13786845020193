import http from "../http-common";
import authHeader from './auth-header';

class UserDataService {
  getUsersByOrg(id) {
    return http.get(`/users/${id}/organization`, { headers: authHeader() });
  }

  getUser(id) {
    return http.get(`/users/${id}`, { headers: authHeader() });
  }

  updateUser(user) {
    return http.put(`/users/${user.id}`, user, { headers: authHeader() });
  }

  newUser(user) {
    return http.post(`/users`, user, { headers: authHeader() });
  }

  updatePassword(user){
    return http.put(`/users/${user.id}/${user.key}/update`, user);
  }

  checkResetLink(user){
    return http.put(`/users/${user.userId}/${user.key}/check`, user);
  }

}

export default new UserDataService();
