import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function PrivacyStatement() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a href="#" onClick={handleShow}>
        Privacy Statement
      </a>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Statement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mainpage-wrapper privacyStatement">
            <p>Last revised: June 16, 2020</p>
            <h4>Introduction</h4>
            <p>Northcross Group (also referred to below as “NCG”, "we", "us" and "our") is a privacy conscious
              organization. This Privacy Notice explains how we protect your information gathered via (1) the Web pages
              of the NorthcrossGroup.com website or Web pages of any other website with respect to which this Privacy
              Notice is posted or linked (such Web pages are collectively referred to as the "Website"), (2)
              downloadable applications accessed from mobile devices with respect to which this Privacy Notice is posted
              or linked ("Mobile Apps") or (3) any other mode of interacting with you relating to Northcross Group
              communications, such as online or offline newsletters and magazines, that reference this Privacy Notice
              (“Communication”). By using the Website or Mobile Apps, or continuing to receive Communications, you agree
              to the use of such information in accordance with this Privacy Notice.
            </p>
            <h4>Information Collection</h4>
            <p>We may collect personal information and non-personal information that you might consider confidential
              (collectively, “Information”) when you interact with the Website or Mobile Apps, such as if you choose to
              register or create a user profile on the Website or a Mobile App (e.g., to gain access to specific
              content, attend a hosted event, respond to a survey or request communications about specific areas of
              interest), or otherwise provide it to us (e.g., to respond to a questionnaire on the Website). Depending
              on the specific Communication you are receiving, or the Web page of the Website or Mobile App you are
              using, personal information may consist of your name, current job title, company address, email address,
              telephone and fax numbers, correspondence with you, which newsletters you subscribe to, and other
              information you provide to us. Non-personal information could be information about your company that it
              considers valuable and that is not generally known.
            </p>
            <p>When using a Mobile App, in addition to contact information, we may also collect information relating to
              your device, including your device model, operating system, browser type, unique device identifier, IP
              address, mobile phone number, mobile network carrier, location, and the way you are using the Mobile App.
              The information collected will depend on the functionality of the specific Mobile App you are using. If
              any other information is collected relating to your use of a Mobile App, such information will be
              specified in the Mobile App.
            </p>
            <p>We may also collect personal information if you register for the Website using a third-party social
              network account (e.g., LinkedIn, Facebook, and Twitter). For example, the Website or a Mobile App may
              allow you to login using your social network account credentials. We may collect the user name associated
              with that social media account and any information or content you have permitted the social media network
              to share with us, such as your profile picture, email address, and birthday. The information we collect in
              this manner may depend on the privacy settings you have with the social network site, so please review the
              privacy statement or policy of the applicable social network site. When you access the Website through
              your social network account, you are authorizing us to collect and use your personal information in
              accordance with this Privacy Notice.
            </p>
            <p>The Website may also collect content that you provide, including postings on blogs, forums, wikis, and
              other social media applications and services that we may provide, and non-personal information that you
              may consider confidential.
            </p>
            <p>We do not collect sensitive personal information (i.e., data relating to race or ethnic origin, religious
              or philosophical beliefs, trade union membership, political opinions, medical or health conditions, or
              information specifying the sex life or sexual orientation of an individual) from users. If you provide
              confidential information you represent that you are authorized to provide that information to us.
            </p>
            <h4>Log Information, Cookies and Web Beacons</h4>
            <p>The Website and Mobile Apps collect standard Internet log information including your IP address, browser
              type and language, access times and referring website addresses. To ensure that the Website, Mobile Apps
              and Communications are well managed and to facilitate improved navigation, we or our service provider(s)
              may also use cookies (small text files stored in a user's browser) or Web beacons (electronic images that
              allow us to count users who have accessed particular content and to access certain cookies) to collect
              aggregate data. Additional information on how we use cookies and other tracking technologies and how you
              can control these can be found in our Cookie Notice.
            </p>
            <h4>Information Use</h4>
            <p>We collect and use Information with your consent to provide you with access to the Website, Mobile Apps
              and Communications, or where we have a legitimate business interest in or other legal basis for, such
              collection and use. Information you submit to us may be used to manage our relationship with you,
              including any of your requests, or to customize or improve the Website or a Mobile App and related
              services offered to you, or to provide you with customized advice.
            </p>
            <p>We may also use your personal information for marketing purposes, or to send you promotional materials or
              communications regarding services provided by us that we feel may be of interest to you. We may also
              contact you to seek feedback on services provided or for market or other research purposes. Your personal
              information may also be used to protect our rights or property and that of our users.
            </p>
            <p>You may at any time request that we discontinue sending you Communications, emails or other
              correspondence.
            </p>
            <h4>Disclosure of Information to Third Parties</h4>
            <p>We may provide your personal information to third parties we have a business relationship with to provide
              you with information that could be of interest to you and conduct market or other research. Information
              may also be disclosed to third parties: in order to respond to your requests or inquiries; as part of a
              corporate transaction (such as a sale, divestiture, reorganization, merger or acquisition); or where those
              parties handle information on our behalf.
            </p>
            <p>Information may also be disclosed to law enforcement, regulatory or other government agencies, or to
              other third parties, in each case to comply with legal, regulatory, or national security obligations or
              requests.
            </p>
            <p>In relation to Mobile Apps, we may also share your usage and personal information with mobile carriers
              and platform providers, as well as any other entities described in any particular Mobile App in connection
              with the operation of the Mobile App.
            </p>
            <p>In relation to Communications, we may also share your personal information with service providers in
              connection with the delivery of such Communications.
            </p>
            <p>All of these disclosures may involve the transfer of personal information to countries or regions without
              data protection rules similar to those in effect in your area of residence.
            </p>
            <p>By providing Information with respect to a Communication or through the Website or a Mobile App, you are
              consenting to the disclosures described above.
            </p>
            <p>Any personal information that we have referenced above under “Information Collection” may be disclosed to
              the third parties identified in this section for the purposes set forth herein.
            </p>
            <h4>Selling of Information</h4>
            <p>We do not sell any Information that is collected under this Privacy Notice.</p>
            <h4>Blogs, Forums, Wikis and other Social Media</h4>
            <p>The Website may host various blogs, forums, wikis and other social media applications or services that
              allow you to share content with other users (collectively Social Media Applications). Any personal
              information or other information that you contribute to any Social Media Application can be read,
              collected and used by other users of that Social Media Application over whom we have little or no control.
              Therefore, we are not responsible for any other user's use, misuse or misappropriation of any personal
              information or other information that you contribute to any Social Media Application.</p>
            <h4>Privacy Practices of Third Parties</h4>
            <p>This Privacy Notice only addresses the use and disclosure of information collected by Northcross Group
              through your interaction with the Website and the Mobile Apps and through other interactions with
              Northcross Group relating to a Communication. Other websites or mobile apps that may be accessible through
              links from the Communication, Website, or Mobile Apps have their own privacy statements and personal
              information collection, use and disclosure practices. We encourage you to familiarize yourself with the
              privacy statements provided by these other parties prior to providing them with information.</p>
            <h4>Information Retention</h4>
            <p>We retain Information as necessary for the duration of the purpose outlined in this Privacy Notice.
              Notwithstanding the duration of the purpose, we may retain your information if necessary to comply with
              our legal or professional obligations, enforce our agreements, or resolve disputes.</p>
            <h4>Rights to Access and Control Your Personal Information</h4>
            <p>You have choices about how your personal information is collected, used and shared.
            </p>
            <p>If you choose to register for a Communication or with the Website or a Mobile App, you may access your
              user profile, correct and update your details, or unsubscribe at any time. If you decide that you no
              longer wish to have your third-party social network site account (e.g., LinkedIn, Facebook, or Twitter)
              linked to your profile on the Website or a Mobile App, you may change your account settings at any time.
              If you have any problem accessing your profile, or would like to request a copy of your profile, please <a
                href="mailto:info@cmmcquestionnaire.com">contact us</a>.
            </p>
            <p>Depending on the jurisdiction in which you are located, you may have the right to request that we modify,
              delete, or stop processing your personal information, and you may also have the right to request that we
              provide the following information regarding the personal information we hold about you:
            </p>
            <ul>
              <li><p>The categories and/or specific pieces of personal information we collected</p></li>
              <li><p>The categories of sources from which personal information is collected</p></li>
              <li><p>The business or commercial purpose for collecting personal information</p></li>
              <li><p>The categories of third parties with whom we shared personal information</p></li>
            </ul>
            <p>To exercise any of your rights under applicable law described above regarding your personal information,
              please <a href="mailto:info@cmmcquestionnaire.com">contact us</a>. When contacting us, please provide your name and email
              address. Once we receive your request, we may ask you to provide additional information to enable us to
              respond.
            </p>
            <p>Applicable laws may also give you the right to lodge a complaint with a local supervisory authority
              related to this Privacy Notice.
            </p>
            <p>We will not discriminate against you for exercising any of your rights with respect to your personal
              information.
            </p>
            <p>This section does not apply to non-personal information that you might consider confidential, as such
              information is not retained by us beyond the Website session. If you have any concern about how such
              non-personal information might be used, simply do not provide it to us.
            </p>
            <h4>Special Notices to California Residents</h4>
            <p>If you are a California resident, you are entitled to request information concerning any personal
              information you provide to us as part of an established business relationship for personal, family, or
              household purposes that we disclose to nonaffiliated third parties for their direct marketing purposes. We
              do not share such personal information with nonaffiliated third parties for such purposes.
            </p>
            <h4>Do Not Track</h4>
            <p>"Do Not Track" is a preference you can set in your Web browser to let the websites you visit know that
              you do not want them collecting information about you. The Website does not currently respond to a "Do Not
              Track" or similar signal. We do not engage in the collection of personal information about your online
              activities over time and across third party websites or online services to provide targeted advertising
              and we do not allow third parties to collect such personal information when you use the Website.
            </p>
            <h4>Information Security</h4>
            <p>We have in place reasonable commercial standards of technology and operational security to protect all
              Information provided by users with respect to Communications, or via the Website or Mobile Apps, from
              unauthorized access, disclosure, alteration or destruction.
            </p>
            <h4>Children's Privacy Protection</h4>
            <p>We understand the importance of protecting children's online privacy. Communications, the Website and
              Mobile Apps are not designed for or intentionally targeted at children under the age of 13. It is not our
              policy to intentionally collect or maintain information about anyone under the age of 13. If we obtain
              actual knowledge that we have received such information, we will take steps to delete it.
            </p>
            <h4>Changes to our Privacy Notice</h4>
            <p>In addition to describing our current privacy practices, this Privacy Notice also describes the
              categories of personal information we collected, disclosed, or sold during the preceding 12 months. We may
              modify or amend this Privacy Notice from time to time at our discretion. When we make changes to this
              Privacy Notice, we will amend the revision date at the top of this page and such modified or amended
              Privacy Notice shall be effective as to you and your information as of that revision date. We encourage
              you to periodically review this Privacy Notice to view any updates.
            </p>
            <h4>Contact Information</h4>
            <p>If you have any questions or concerns regarding your privacy while using the Website or a Mobile App, or
              upon receipt of a Communication, please <a href="mailto:info@cmmcquestionnaire.com">contact us</a>.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PrivacyStatement;