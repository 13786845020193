import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import AuthService from "../../services/auth.service";
import UserDataService from "../../services/user.service";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      email: "",
      message: "",
      submitted: ""
    };
  }

  componentDidMount() {

  }

  // change password
  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  // submit reset request
  onSubmit() {
    //call auth service
    let body ={
      email: this.state.email
    }
    AuthService.forgotPassword(body)
      .then(res => {
        this.setState({
          message: "Check your email for a link to reset your password.",
          submitted: true
        });
      })
      .catch(err => {
        this.setState({
          message: "error, contact the website administrator for help",
          submitted: false
        });
      })
    
  }

  // ...

  render() {
    return (
      <div className="cmmc qstnaire client-qstnaire client-view login ">
        <div className="container fluid login">
          <div className="login-wrapper">
            <div className="cmmc-logo-wrapper"></div>
            <div className="login-container col-lg-7">
              {this.state.message && (this.state.message != "") ? (
                <div className="form-group">
                  <div className="alert alert-secondary" role="alert">
                    {this.state.message}
                  </div>
                </div>
              ) : null}
              {this.state.submitted != true ? (
                <div>
                  <span className="instructions">
                    Please enter your email address below. If it is found in the system you will receive a link to reset your password.
                  </span>
                  <div className="username form-control">
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      required />
                  </div>
                  <div className="submit-button-wrapper">
                    <button type="button" onClick={this.onSubmit} className="btn btn-navy hover-blue btn-lg" value="submit">Send Email</button>
                  </div>
                </div>
              ) : null}

            </div>
          </div>
        </div>
      </div>
    );
  }
}
