import React, { Component } from "react";

import AuthService from "../services/auth.service";
import OrganizationDataService from "../services/organization.service";
import UnderstandingResults from "./understandingResults.component.js";

export default class Summary extends Component {
  constructor(props) {
    super(props);

    this.getOrgInfo = this.getOrgInfo.bind(this);
    this.getStarted = this.getStarted.bind(this);

    this.state = {
      maturity: 0,
      questionnaireState: "",
      currentUser: AuthService.getCurrentUser(),
      orgInfo: "",
      completion: ""
    };
    this.getMaturityLevel();
    this.getOrgInfo();
  }
  componentDidMount() {
  }

  renderLevel(maturity) {
    let text = "";
    if(this.state.questionnaireState){
      
      if(maturity === 0){
        let level_stats = this.state.questionnaireState.levels[1];

        let missingRequirementsText = '';
        if(level_stats.domains.missing.length > 0) {
          text = "Pre-Level 1 (Missing " + level_stats.domains.missing.length + " of " + level_stats.domains.count + " Level 1 Domains)";
          missingRequirementsText = <><b>Maturity Level 1 Missing Domains:</b><ul>{level_stats.domains.missing.map(t => <li>{t}</li>)}</ul></>;
        }
        // all the domains are complete!
        else {
          text = "Pre-Level 1 (Missing " + level_stats.capabilities.missing.length + " of " + level_stats.capabilities.count + " Level 1 Capabilities)";
          missingRequirementsText =<><b>Maturity Level 1 Missing Capabilities:</b> <ul>{level_stats.capabilities.missing.map(t => <li>{t}</li>)}</ul></>;
        }


        return <label><div class="info-expanded"><span className="tip"> {missingRequirementsText}</span></div><b>{text}</b></label>;
      }
      else{
        text = "Level " + maturity;
      }
    }

    return <label><b>{text}</b></label>;
  }

  getProcessesText(processes) {
    console.log(this.state.questionnaireState);
    let processesText = "";
    let processValues = [];
    if(processes) {

      if (processes.files === true) {
        processValues.push("Policies & Procedures");
      }
      if (processes.resourcePlan === true) {
        processValues.push("Resource Plans");
      }
      if (processes.assessmentPlan === true) {
        processValues.push("Reviews & Measurements");
      }
      if (processes.improvementPlan === true) {
        processValues.push("Continuous Improvement Processes");
      }

      if (processValues.length) {
        for (let i = 0; i < processValues.length; i++) {
          if (i) {
            processesText += ", ";
          }
          processesText += processValues[i];
        }
        processesText += " in place";
      } else {
        processesText = 'None';
      }
    }    
    return processesText;
  }

  getCapabilitiesText(maturity) {
    if(maturity == 0 ) {
      //If the questionnaire only reached "pre-level 1" treat this as-if it is level 1.
      maturity = 1;
    }

    if (this.state.questionnaireState) {
      let totalCompleted = 0;
      let levels = this.state.questionnaireState.levels; 
      // each level has additional questions to complete. Therefore iterating up to the current maturity level is required
      // to determine how many total questions have been achieved for that level.

      let totalCapabilities = 0;

      Object.keys(levels).forEach((key) => {
        let item = levels[key];
        totalCapabilities += item.capabilities.completed;
        if(key <= maturity ) {
          totalCompleted += item.capabilities.completed; 
        }
      });
      
      // for(let i = 1; i <= maturity; i++) {
      //   totalCompleted += levels[i].capabilities.completed;
      // }

      return (<label>{totalCompleted} Level {maturity} Capabilities, Plus {totalCapabilities - totalCompleted} Additional</label>)
    }
    return null;
  }


  getStepOneText(maturity) {
    switch (maturity) {
      case 0:
        return (
          <label>Conduct a third party assessment to validate the current state and what is needed for Level 1.</label>
        );
      case 5:
        return (
          <label>Conduct a third party assessment to validate the Level 5 projection.</label>
        );
      default:
        return (
          <label>Conduct a third party assessment to validate the Level {maturity} projection and what is needed to accomplish Level {maturity + 1}.</label>
        );
    }
  }

  getMaturityLevel() {
    OrganizationDataService.getLevelByOrg({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          maturity: response.data.data.maturity,
          questionnaireState: response.data.data
        });
      })
  }

  getOrgInfo() {
    OrganizationDataService.getOrgInfo({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          orgInfo: response.data.data[0],
        });
      })
  }

  getStarted(){
    let data = {
      organizationId: this.state.currentUser.organizationId,
      organizationName: this.state.orgInfo.name,
      userName: this.state.currentUser.name,
      userEmail: this.state.currentUser.email
    }
    OrganizationDataService.getStarted(data)
    .then(response => {
      this.getOrgInfo();
    })
  }

  render() {
    return (
      <div className="cmmc qstnaire client-qstnaire client-view submit summary-page prelim-results">
        <div>
          <UnderstandingResults/>
        </div>

        <div className="main-content container-fluid">
          <div className="col-lg-12 summary-section-container">
            <div className="summary-section-wrapper maturity-level-projection">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 summary-section-label">
                <div className="info-button">
                  <p className="info-label">i</p>
                  <div className="info-expanded">
                    <span className="tip">
                      <p>The Maturity Level Projection is based on your response to which domains, capabilities, and processes are performed in your organization.
                      </p>
                      <p>In order to progress to the next Maturity Level, all the requirements of the previous levels must be met.
                      </p>
                    </span>
                  </div>
                </div>
                <p>questionnaire maturity level projection:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 summary-section-value">
                {this.renderLevel(this.state.maturity)}
              </div>
            </div>
            <div className="underline">
              <p className="col-lg-6 label-info">projected level of the 5 CMMC levels based on your questionnaire
                responses</p>
            </div>
          </div>
          <div className="col-lg-12 summary-section-container">
            <div className="summary-section-wrapper practices">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 summary-section-label">
                <div className="info-button">
                  <p className="info-label">i</p>
                  <div className="info-expanded">
                    <span className="tip">
                      <p>The listing of Capabilities provides a count of total capabilities in place at your organization. This list is broken down by the capabilities for your current Maturity Level plus any additional that are working towards a subsequent maturity Level.
                      </p>
                    </span>
                  </div>
                </div>
                <p>capabilities:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 summary-section-value">
                {this.getCapabilitiesText(this.state.maturity)}
              </div>
            </div>
            <div className="underline">
              <p className="col-lg-6 label-info">Projected CMMC capabilities in place at your organization</p>
            </div>
          </div>
          <div className="col-lg-12 summary-section-container">
            <div className="summary-section-wrapper processes">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 summary-section-label">
                <div className="info-button">
                  <p className="info-label">i</p>
                  <div className="info-expanded">
                    <span className="tip">
                      <p>The listing of Processes you indicated  are in place for your organization.</p>
                    </span>
                  </div>
                </div>
                <p>processes:</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 summary-section-value">
                <label>{this.getProcessesText(this.state.questionnaireState.processes)}</label>
              </div>
            </div>
            <div className="underline">
              <p className="col-lg-6 label-info">Projected CMMC processes in place at your organization</p>
            </div>
          </div>
          <div className="col-lg-12 summary-section-container">
            <div className="summary-section-wrapper next-steps">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-6 summary-section-label">
                <p className="col-lg-6">next steps:</p>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-6 col-xs-6 summary-section-value">
                <div className="step-1 step col-lg-12">
                  <span>#1</span>
                  <div className="step-text">
                    {this.getStepOneText(this.state.maturity)}
                  </div>
                </div>
                <div className="step-2 step col-lg-12">
                  <span>#2</span>
                  <div className="step-text">
                    <p>Planning session to map out efforts to your target Maturity Level and rate of accomplishment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.orgInfo && !this.state.orgInfo.getStarted ? (
            <div className="col-lg-12 button-container">
              <button type="button" className="btn btn-navy hover-blue btn-lg" onClick={() => this.getStarted()}>Contact me about next steps</button>{' '}
            </div>
          ) : (
            <div className="confirmation-message col-lg-6 col-md-8 col-sm-8">
              <p>Your request to be contacted about next steps has been received. Someone from our organization will contact you via email within one business day.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
