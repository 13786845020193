import React, {useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
function UnderstandingResults() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <a href="#" onClick={handleShow} className="understanding-results">
        <p>Understanding Your Results</p>
      </a>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Understanding Your Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mainpage-wrapper understandYourResults">
            <h4>Understanding the Questionnaire Results</h4>
            <p>Your questionnaire responses are used to project your current state Maturity Level based on the CMMC model.  The projected level is based on the Domains, Capabilities, and Processes your responses indicated are in place at your organization.</p>

            <h4>Your Current Cybersecurity Safeguards Compared to the CMMC Model</h4>
            <p>The CMMC model is made up of seventeen (17) Domains, forty-three (43) Capabilities, and one hundred seventy-one (171) Practices.  Practices are components of Capabilities, which are in turn components of Domains.  Individual Practices are required by Maturity Level. Capabilities and Domains can span multiple Maturity Levels.</p>

            <p>Under the CMMC model, six Domains are required to be performed for Maturity Level 1.  To reach level 2, nine additional Domains are required. For Level 3-5, all 17 Domains are required.</p>

            <h4>CMMC Model Capabilities and Practices</h4>
            <p>Each CMMC Domain is comprised of one or more Capabilities.  Capabilities are comprised of Practices that are required for each Maturity Level.  The 171 Practices are required by Maturity Level as follows:</p>
            <ul>
              <li>Level 1 = 17 Practices</li>
              <li>Level 2 = 55 Practices</li>
              <li>Level 3 = 58 Practices</li>
              <li>Level 4 = 26 Practices</li>
              <li>Level 5 = 15 Practices</li>
            </ul>

            <p>The CMMC model requires certain Processes are in place and performed to achieve Maturity Levels 2-5.  Maturity Level 1 only focuses on the performance of the first seventeen practices without requiring any specific processes.  The CMMC model requirements for Processes are as follows:</p>
            <ul>
              <li>	Level 2 requires documentation covering each Domain with policies and procedures.</li>
              <li>Level 3 requires management through plans for each Domain.</li>
              <li>Level 4 requires effectiveness reviews through measures for each Domain.</li>
              <li>Level 5 requires continuous improvements through standardization and optimization for each Domain across all applicable organizational units.</li>
            </ul>

            <h4>Questionnaire Maturity Level Projections</h4>
            <p>Your Maturity Level Projection is based on the Domains, Capabilities, and Processes you responded are in place in your organization.  The possible values you may have for your Maturity Level Projection are:</p>
            <ul>
              <li>Pre-Level 1 (not yet able to meet certification)</li>
              <li>Level 1</li>
              <li>Level 2</li>
              <li>Level 3</li>
              <li>Level 4</li>
              <li>Level 5</li>
            </ul>

            <p>To reach a given Maturity Level, you must satisfy all the requirements of that Level plus all the requirements for preceding Levels.</p>

            <p>For example, to reach Maturity Level 3, you must satisfy all the Maturity Level 1 and 2 requirements in addition to those of Level 3.  If you implemented items required for Levels 4 or 5, they do not count towards your accomplishment of Level 3.</p>

            <p>In addition to your projected Maturity Level, your total number of Capabilities and a list of Processes based on your responses is also provided.   A link to view all your response details is also provided.</p>

            <p>The questionnaire does not ask about specific Practices, leaving these to be validated as part of a subsequent assessment that we can provide to independently verify your current state.</p>

            <h4>Next Steps after completing the Baseline Questionnaire</h4>
            <p>The Baseline Questionnaire captures your current state view of your organization’s cybersecurity compared to the CMMC model.  That view should be verified and assessed to determine your ability to be successful with the actual certification process.</p>

            <p>We use your Baseline Questionnaire results to streamline and focus the assessment process.  This third-party assessment will examine your level of readiness for certification process.  Depending on where you currently stand, we will develop a roadmap for you to get ready for certification or to accomplish a higher Maturity Level.</p>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UnderstandingResults;