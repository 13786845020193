import React from "react";
import { Navbar, Nav } from 'react-bootstrap';
import CookieNotice from './cookieNotice.component.js';
import PrivacyStatement from './privacyStatement.component.js';
import TermsofUse from './termsofUse.component.js';

function Footer() {
    return (
        <div className="col-md-12">
            <nav className="topNav">
                <Navbar className="color-nav" variant="dark" fixed="bottom">
                    <Nav className="mr-auto">
                        <Navbar.Text>
                          Powered by NCG Orchestration. All Rights Reserved.
                        </Navbar.Text>
                        <Navbar.Text>
                          For further inquiries, please contact us:
                          <a className="site-link" href="mailto:inquiries@hka.com"> inquiries@hka.com</a>
                        </Navbar.Text>

                    </Nav>
                    <Nav className="mr-auto links">
                      <div className="site-links-container">
                        <a href="https://www.hka.com/about-us/" className="site-link">About</a>
                        <a href="https://www.hka.com/services/" className="site-link">Services</a>
                        <a href="https://www.hka.com/expert-centre/" className="site-link">Expert Centre</a>
                      </div>
                      <div className="social-media-links-container">
                        <a href="https://www.facebook.com/HKAGlobal/" className="social-media-link facebook"/>
                        <a href="https://twitter.com/HKAGlobal" className="social-media-link twitter"/>
                        <a href="https://www.linkedin.com/company/11118657/admin/" className="social-media-link linkedin"/>
                      </div>
                    </Nav>
                </Navbar>
            </nav>
        </div>
    );
}

export default Footer;
