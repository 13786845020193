import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";

import AuthService from "../../services/auth.service";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    let message = "";
    if(props.location.search === "?verify"){
      message= "Your email has been verified";
    }
    if(props.location.search === "?logout"){
      message= "You have been logged out";
    }
    if(props.location.search === "?reset"){
      message= "Your password has been reset, please login below";
    }
    if(props.location.search === "?invalid"){
      message= "That link is no longer valid";
    }

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: message
    };

    
  }

  componentDidMount() {
    
  }

  // change email field
  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  // change password
  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  // handle login
  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    AuthService.login(this.state.email, this.state.password)
      .then(
        res => {
          this.props.history.push("/dashboard");
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
  }

  // ...

  render() {
    if (AuthService.isUserLoggedIn()) {
      return (          
        <Redirect to="/dashboard" />
      )
    } 
    else {  
      return (
        <div className="cmmc qstnaire client-qstnaire client-view login ">
          <div className="fluid login">
            <div className="login-wrapper">
              <div className="login-container col-xl-6 col-lg-7">
                  {this.state.message && (this.state.message != "") ? (
                        <div className="form-group">
                          <div className="alert alert-secondary" role="alert">
                            {this.state.message}
                          </div>
                        </div>
                  ):null}
                  <div className="username form-control">
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        required />
                  </div>
                  <div className="password form-control">
                      <input
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        required />
                        <a href="/forgot" className="forgot-password">
                            <a className="forgot-icon">?
                              <span className="hover">Forgot password?</span>
                            </a>
                        </a>
                  </div>
                  <div className="submit-button-wrapper">
                    <button type="button" onClick={this.handleLogin} className="btn btn-navy hover-blue btn-lg" value="submit">submit</button>
                  </div>
                  <div className="register">
                    <Link to="/register" >Create Account</Link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
