import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthService from "../services/auth.service";
import OrganizationDataService from "../services/organization.service";
import { Button } from 'react-bootstrap';
export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.getOrgInfo = this.getOrgInfo.bind(this);
    this.getCompletion = this.getCompletion.bind(this);
    this.submitQuestionnaire = this.submitQuestionnaire.bind(this);

    this.state = {
      currentUser: AuthService.getCurrentUser(),
      orgInfo: "",
      completion: "",
      maturity: ""
    };

  }

  componentDidMount() {
    this.getOrgInfo();
    this.getCompletion();
    this.getMaturityLevel();
  }

  getOrgInfo() {
    OrganizationDataService.getOrgInfo({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          orgInfo: response.data.data[0],
        });
      })
  }

  getCompletion() {
    OrganizationDataService.getCompletionByOrg({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          completion: response.data.data,
        });
      })
  }

  submitQuestionnaire() {
    let orgInfo = this.state.orgInfo;
    let org = {
      id: orgInfo.id,
      submitted: true,
      submittedUser: this.state.currentUser.id
    }
    OrganizationDataService.submit(org)
      .then(response => {
        orgInfo.submitted = true;
        this.setState({
          orgInfo: orgInfo
        })
        this.props.history.push("/summary");
        window.location.reload();
      })
  }

  getMaturityLevel() {
    OrganizationDataService.getLevelByOrg({
      organizationId: this.state.currentUser.organizationId
    })
      .then(response => {
        this.setState({
          maturity: response.data.data.currentLevel,
        });
      })
  }

  // ...

  render() {
    return (
      <div className="cmmc">
        <div className="results preliminary self-assess-portal submit">
            <div className="main-content">
              {this.state.orgInfo && this.state.orgInfo.submitted ? (
                <div className="current-level-wrapper col-lg-12 col-md-12">
                  <div className={"level-container level-" + this.state.maturity}>
                    <p>Preliminary Maturity Level:</p>
                    {this.state.maturity > 0 ? (
                      <p className="level">Level {this.state.maturity}</p>
                    ):(
                      <p className="level">Pre-Level 1</p>
                    )}
                  </div>
                </div >
              ) : null}
              <div className="container">
                <div className="dashboard-text">
                  <p>HKA is the world’s leading consultancy of choice for multi-disciplinary expert and specialist services in risk mitigation and dispute resolution within the capital projects and infrastructure sector.</p>
                  <p>We also have particular experience advising clients on the economic impact of commercial and investment treaty disputes and in forensic accounting matters.
                  In addition, HKA supports companies that conduct business with the US Federal Government, providing them with consulting services on complex government contracting matters.</p>
                </div>
                {this.state.currentUser.role !== "readonly" ? (
                  <div className="col-lg-3 col-sm-12 col-xs-6 sap-section">
                  {this.state.orgInfo && !this.state.orgInfo.submitted ? (
                    <Link className="complete-assessment" to="/questions">
                      <span className="graphic"></span>
                      {this.state.completion === 0 ? (
                        <p>Click Here to Complete Questionnaire</p>
                      ) : (
                          <p>Complete Questionnaire</p>
                        )}
                      <div className="progress-bar-wrapper">
                        <div className="progress-bar">
                          <span className="progress-fill" style={{
                            width: this.state.completion * 100 +"%"
                          }}>
                          </span>
                        </div>
                        <p>{Math.floor(this.state.completion * 100)}% Complete</p>
                      </div>
                    </Link>
                  ) : (
                      <Link className="complete-assessment" to="/summary">
                        <span className="graphic"></span>
                        <p>View Questionnaire Results & Next Steps</p>
                      </Link>
                    )}
                </div>
                ):null}
                  
                    <div className="col-lg-3 col-sm-12 col-xs-6 sap-section">
                      <Link to="/responses" className="view-submitted-docs">
                        <span className="graphic"></span>
                        <p>View Responses</p>
                      </Link>
                    </div>
                  {this.state.currentUser && this.state.currentUser.role === "fullcontrol" ? (
                    <div className="col-lg-3 col-sm-12 col-xs-6 sap-section">
                      <Link to="/users" className="manage-users">
                        <span className="graphic"></span>
                        <p>Manage Users</p>
                      </Link>
                    </div>
                  ):null}
                  {!this.state.orgInfo.submitted && this.state.currentUser && this.state.currentUser.role === "fullcontrol"  && this.state.completion && this.state.completion == 1 ? (
                    <div className="button-container">
                      <button type="button" className="btn btn-outline-primary btn-lg" onClick={()=>this.submitQuestionnaire()}>submit questionnaire</button>{' '}
                    </div>
                  ) : null}
                </div>
            </div>
          </div>
        <div class="message friendly">
          <h5>Remember:  There are no right or wrong answers…</h5>

          <p>The questionnaire is designed to capture the cybersecurity things you have in place today compared to the CMMC model.</p>
        </div>
      </div>
      
    );
  }
}
